import React from "react";
import { useHistory, useParams } from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import {getSearchUrl, getAuthorUrl, getInstituteUrl} from "../SearchService";
import Skeleton from '@material-ui/lab/Skeleton';
import Pagination from '@material-ui/lab/Pagination';
import {makeStyles} from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const integer = (v) => {return parseInt(v,10)};

const useStyles = makeStyles(theme => ({
    inlineTextSkeleton: {
        height: "24px",
        marginRight: "0.25em",
    },
    inlineTextSkeletonLast: {
        height: "24px",
        marginRight: 0,
    },
    flex: {
        flex: "1 0 auto"
    }
}));

export const Paginator = ({numOfPages,currentPage,disabled}) =>{
    currentPage = (currentPage || 1) * 1;
    numOfPages = numOfPages * 1;
    // const [page, setPage] = React.useState(currentPage);
    const params = useParams();
    const history = useHistory();
    const handleChange = (event, value) => {
        event.preventDefault();
        // setPage(value);
        history.push(!!params.login ? getAuthorUrl({...params,page:value}) : (!!params.cds ? getInstituteUrl({...params,page:value}) : getSearchUrl({...params,page:value})));
    };
    return (
        <Pagination disabled={disabled} showFirstButton showLastButton count={numOfPages} page={currentPage} onChange={handleChange} />
    );
}

export const Loader = ({numOfPages,currentPage}) => {
    const classes = useStyles();
    return <>
        <Skeleton className={classes.inlineTextSkeleton} width={"17em"} />
        <span style={{flex: "1 0 auto"}}/>
        <Paginator numOfPages={numOfPages} currentPage={currentPage} disabled={true}/>
    </>
}

export const Error = ({numOfPages,currentPage}) => <>
    <span style={{flex: "1 0 auto"}}/>
    <Paginator numOfPages={numOfPages} currentPage={currentPage} disabled={true}/>
</>

export const Navigator = ({assetFrom,assetTo,numOfAssets,currentPage,numOfPages}) => {
    const { t } = useTranslation()
    return (<>
        <Typography variant="subtitle1">{t("results_from_to_of",{from: assetFrom, to: assetTo, of: numOfAssets})}</Typography>
        <span style={{flex: "1 0 auto"}}/>
        <Paginator numOfPages={numOfPages} currentPage={currentPage}/>
    </>);
}

const ResultsNavigator = ({start,numFound,numOfDocs}) => {
    const params = useParams();
    const {page} = params;
    const rows = integer(process.env.REACT_APP_ROWS_DEFAULT);
    const assetFrom = numOfDocs > 0 ? (start*1 + 1) : 0;
    const assetTo = numOfDocs + start;
    const numOfPages = Math.ceil(numFound/rows);
    const numOfAssets = numFound;
    return <Navigator
        assetFrom={assetFrom}
        assetTo={assetTo}
        numOfAssets={numOfAssets}
        currentPage={parseInt(page)}
        numOfPages={numOfPages}
    />;
};

export default ResultsNavigator;