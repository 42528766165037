import React from "react";
import { useTranslation } from 'react-i18next'
import useAxios, {configure} from 'axios-hooks'
import { useParams } from 'react-router-dom';
import {makeStyles} from "@material-ui/core";
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Skeleton from "@material-ui/lab/Skeleton";
import { Title, Body } from './StaticPages';
import {getSolrQueryUrl} from "../SearchService";
import PrintError from '../components/PrintError';
import ResultsList, {Loader as ResultsListLoader} from "../components/ResultsList";
import ResultsNavigator, {Loader as ResultsNavigatorLoader} from "../components/ResultsNavigator";

const useStyles = makeStyles(theme => ({
    pp: {
        "& p": {
            margin: 0,
        },
        "& p + p": {
            margin: "0.5em 0 0 0",
        },
    },
    chip: {
        margin: "0 8px 8px 0",
        textTransform: "uppercase",
        maxWidth: "calc(100% - 8px)",
    },
    publications: {
        clear: "left",
        backgroundColor: theme.palette.background.default,
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(-4),
        marginBottom: theme.spacing(4),
        marginLeft: theme.spacing(-4),
        padding: theme.spacing(4),
        "& h3": {
            marginBottom: theme.spacing(2),
        },
    },
    toolbar: {
        display: "block",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: "flex",
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    avatar: {
        margin:"0px 16px 16px 0",
        height:150,
        width:150,
        [theme.breakpoints.up('md')]: {
            float:"left",
        }
    },
    aroundAvatar: {
        [theme.breakpoints.up('md')]: {
            marginLeft: 166,
        }
    }
}));

const InstituteInfo = () => {
    const {cds} = useParams();
    const [{ data, loading, error }] = useAxios(`${process.env.REACT_APP_INSTITUTEINFO_ENDPOINT}${cds}`);
    if (error) {
        return (<Title>{cds}</Title>);
    }
    if (loading || !data) {
        return (<Title><Skeleton /></Title>);
    }
    return <Title>{data[0].sigla}, {data[0].descrizione}</Title>
}

const Publications = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const params = useParams();
    const solrQueryUrl = getSolrQueryUrl(params);
    configure({cache:false})
    const [{ data, loading, error }] = useAxios(solrQueryUrl);
    if(error) return (
        <Box className={classes.publications}>
            <Typography component="h3" variant="h6">{t("Publications")}</Typography>
            <Typography>{t("Search error")}</Typography>
            <Typography variant="subtitle1">{t("An error occurred while performing the search")}</Typography>
            <Typography component="p">{t("The system may be too busy or there may be network problems")}.</Typography>
            <Typography component="p">{t("If the error is caused by problems within the system, we are probably already working on it")}.</Typography>
            <PrintError {...{error}} />
        </Box>
    );
    if(loading || !data) return (
        <Box className={classes.publications}>
            <Typography component="h3" variant="h6"><Skeleton width={"6em"} /></Typography>
            <Toolbar className={classes.toolbar}><ResultsNavigatorLoader /></Toolbar>
            <ResultsListLoader />
            <Toolbar className={classes.toolbar}><ResultsNavigatorLoader /></Toolbar>
        </Box>
    );
    const {response} = data;
    const {docs,numFound,start} = response || {};
    if(numFound === 0) return null;
    return (
        <Box className={classes.publications}>
            <Typography component="h3" variant="h6">{t("Publications")}</Typography>
            <Toolbar className={classes.toolbar}><ResultsNavigator start={start} numFound={numFound} numOfDocs={docs.length} /></Toolbar>
            <ResultsList docs={docs} />
            <Toolbar className={classes.toolbar}><ResultsNavigator start={start} numFound={numFound} numOfDocs={docs.length} /></Toolbar>
        </Box>
    );
}

const InstituteViewPage = () => {
    return (
        <Body>
            <InstituteInfo />
            <Publications />
        </Body>
    );
};

export default InstituteViewPage;
