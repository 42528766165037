import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    makeStyles,
    Button,
} from "@material-ui/core";
const useStyles = makeStyles(theme => ({
    selectLng: {
        "& *": {
            color: "#fff",
        }
    },
    selected: {
        borderBottom:"1px solid",
        fontWeight:900,
    }
}));

const SetLang = () => {
    const { i18n } = useTranslation();
    const [currLang,setCurrLang] = useState(i18n.language);
    const changeLanguage = () => {
        const lng = currLang === 'it' ? 'en' : 'it';
        i18n.changeLanguage(lng);
        setCurrLang(lng);
    };
    const classes = useStyles();
    return (
        <Button
            onClick={() => changeLanguage()}
            color="inherit">
                <span className={currLang==="it" ? classes.selected : ""}>IT</span>
                &nbsp;|&nbsp;
                <span className={currLang==="en" ? classes.selected : ""}>EN</span>
        </Button>
    );
}

export default SetLang;