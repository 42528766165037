import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    hChip: {
        marginRight: theme.spacing(1)
    },
    skInfo: {
        width: "10em"
    },
    abstract: {
        textOverflow: "ellipsis",
        position: "relative",
        overflow: "hidden",
        maxHeight: "calc(1.43em * 4)",
        "&::after": {
            content: '""',
            textAlign: "right",
            position: "absolute",
            top: "calc(1.43em * 3)",
            right: 0,
            width: "30%",
            height: "1.43em",
            background: "linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%)",
          }
    },
    documentDataRoot: {
        width: "100%",
    }
}));

const AnnoTipoLingue = ({anno,tipo,lingue}) => {
    const classes = useStyles();
    return <Typography className={classes.title} color="textSecondary" gutterBottom>
        {anno}, {tipo}{lingue && `, ${lingue.join('/').toUpperCase()}`}
    </Typography>;
}
const Titolo = ({text,id}) => {
    if(text) return <Typography variant="h5"><Link component={RouterLink} to={`/doc/${id}`}>{text}</Link></Typography>;
    return '';
}
const Abstract = ({doc}) => {
    const classes = useStyles();
    const text = doc["abstract_s-s"] || doc["sintesi_s-s"] || doc["descrizione_s-s"] || null;
    if(text) return <Box mb={1} mt={1}><Typography variant="body2" component="p" className={classes.abstract}>{text}</Typography></Box>;
    return '';
}

const Links = ({doc}) => {
    if(doc["doi_s-i-s-m"]) {
        const dois = doc["doi_s-i-s-m"].map(doi => doi.replace(/^https?:\/\/dx.doi.org\//,""));
        return <Typography variant="caption" color="textSecondary" component="p">
            DOI: {dois.map((doi,key) => <Link key={key} href={`https://dx.doi.org/${doi}`}>{doi}</Link>)}
        </Typography>;
    }
    return '';
}

const Source = ({doc}) => {
    const rivista = doc["rivista_s-i-s"];
    const sources = doc["source_s-s-m"];
    if(sources && sources.length) {
        return sources.map((source,key) => <Typography key={key} variant="caption" color="textSecondary">{source}</Typography>);
    }
    if(rivista) return <Typography componenet="p" gutterBottom variant="caption" color="textSecondary">{rivista}</Typography>;
    return '';
}

const Autori = ({text}) => (text && text.join(',')) ? <Typography color="textSecondary">{text.join(',')}</Typography> : '';

export const DocumentDataLoader = () => {
    return (
        <>
            <Box style={{display: "flex"}}>
                <Skeleton height={23} width="4em" style={{marginRight: "0.5em"}} />
                <Skeleton height={23} width="12em" style={{marginRight: "0.5em"}} />
                <Skeleton height={23} width="3em" style={{marginRight: "0.5em"}} />
            </Box>
            <Skeleton height={40} width={"70%"} />
            <Box mb={1}><Skeleton height={27} width={"50%"} /></Box>
            <Box mb={1} mt={1}><Skeleton/><Skeleton/><Skeleton/><Skeleton width={"70%"} /></Box>
            <Skeleton width={"40%"}/>
            <Skeleton width={"40%"}/>
        </>
    );
}

export const Loader = () => {
    const classes = useStyles();
    return <Card className={classes.root}>
    <CardContent>
        <DocumentDataLoader />
    </CardContent>
    <CardActions>
    <Button size="small" disabled><Skeleton width={"7em"} /></Button>
    </CardActions>
</Card>
}

export const DocumentData = ({doc}) => {
    const classes = useStyles();
    return (<Box className={classes.documentDataRoot}>
        <AnnoTipoLingue anno={doc['anno_s-i-s']} tipo={doc['tipo_s-i-s']} lingue={doc['lingua_s-i-s-m']} />
        <Titolo text={doc['titolo_s-s']} id={doc["ID"]} />
        <Autori text={doc['autori_s-i-s-m']} />
        <Abstract doc={doc} />
        <Source doc={doc} />
        <Links doc={doc} />
    </Box>)
};

const DocumentListItem = ({doc}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    return (
        <Card className={classes.root}>
            <CardContent>
                <DocumentData doc={doc} />
            </CardContent>
            <CardActions>
                <Button size="small"  component={RouterLink} to={`/doc/${doc["ID"]}`}>{t("View")}</Button>
            </CardActions>
        </Card>
    );
}
export default DocumentListItem;