import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Link, Container } from '@material-ui/core';
import { CCIcon,CCByIcon } from '../assets/ExtraIcons';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(2),
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "& a": {
            textDecoration: "none",
            color: theme.palette.primary.contrastText,
            "&:hover,&:focus,&:active": {
                textDecoration: "underline",
            }
        },
        "& dl": {
            margin: 0,
            padding: 0
        },
        "& dt": {
            margin: 0,
            padding: 0
        },
        "& dd": {
            margin: 0,
            padding: 0
        }
    },
    notes: {
        padding: theme.spacing(2),
        background: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        display: "flex",
        "& a": {
            // textDecoration: "underline",
            color: theme.palette.primary.contrastText,
        }
    },
    ccIcons: {
        verticalAlign: "middle",
        marginRight: "0.25em",
    },
    uLink: {
        textDecoration: "underline"
    },
    navList: {
        margin: 0,
        padding: "0 0 0 1em",
        listStyleType: "'– '",
        "& a": {
            // textDecoration: "underline",
            color: "inherit"
        }
    },
    byDomus: {
        flex: 1,
        textAlign: "right"
    }
}));
const Footer = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (<>
        <Box className={classes.root}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <dl>
                            <dt><a href="/about"><strong>{t("About")}</strong></a></dt>
                            <dd>
                                <ul className={classes.navList}>
                                    <li><a href="/about#intro__subt1">{t("intro__subt1")}</a></li>
                                    <li><a href="/about#intro__subt2">{t("intro__subt2")}</a></li>
                                </ul>
                            </dd>
                        </dl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <dl>
                            <dt><strong>{t("Resources")}</strong></dt>
                            <dd>
                                <ul className={classes.navList}>
                                    <li><a href="/search">{t("Publications")}</a></li>
                                    <li><a href="/authors">{t("Authors")}</a></li>
                                    <li><a href="/stats">{t("Stats")}</a></li>
                                </ul>
                            </dd>
                        </dl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <dl>
                            <dt><a href="/developers"><strong>{t("Developers")}</strong></a></dt>
                            <dd>
                                <ul className={classes.navList}>
                                    <li><a href="/developers#api-rest">API Rest</a></li>
                                    <li><a href="/developers#oai-pmh">OAI-PMH</a></li>
                                </ul>
                            </dd>
                        </dl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <dl>
                            <dt><a href="/contacts"><strong>{t("Contacts")}</strong></a></dt>
                            <dd>
                                <ul className={classes.navList}>
                                    <li><a href="mailto:oapublications@cnr.it">oapublications@cnr.it</a></li>
                                </ul>
                            </dd>
                        </dl>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        <Box className={classes.notes}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <span className={classes.ccIcons}>
                            <CCIcon />
                            <CCByIcon />
                        </span>
                        <Link className={classes.uLink} rel="license" href="http://creativecommons.org/licenses/by/4.0/">{t("Licensed under CC BY 4.0")}</Link>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        
                    </Grid>
                </Grid>
            </Container>
        </Box>
    </>);
}
export default Footer;
