import React from "react";
import useAxios, {configure} from 'axios-hooks'
import { useParams } from 'react-router-dom';
import {makeStyles} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import ResultsList, {Loader as ResultsListLoader} from "../components/ResultsList";
import ResultsNavigator, {Loader as ResultsNavigatorLoader} from "../components/ResultsNavigator";
import {getSolrQueryUrl} from "../SearchService";
import FacetsPanels from "../components/FacetsPanels";
import ZeroResults from "../components/ZeroResults";
import { SearchError } from "./StaticPages";
const useStyles = makeStyles(theme => ({
    flex: {
        display: "flex",
        flexFlow: "column",
        flexGrow: 1
    },
    p: {
        padding: theme.spacing(0,2,2,2)
    },
    grid: {
        flexDirection: "row-reverse",
    },
    toolbar: {
        display: "block",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: "flex",
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    header: {
        background: theme.palette.primary.light,
        marginBottom: "-10rem",
        height: "10rem", 
    },
    appbar: {
        background: theme.palette.primary.light,
        "& *": {
            color: "inherit",
        }
    }
}));

const Template = ({Navigator,Results,Filters}) => {
    const classes = useStyles();
    return <div className={classes.flex}>
        <AppBar position="static" elevation={0} className={classes.appbar}>
            {Navigator && <Toolbar className={classes.toolbar}>{Navigator}</Toolbar>}
        </AppBar>
        <div className={classes.header}></div>
        <Box mt={!!Navigator ? 0 : 2} className={`${classes.flex} ${classes.p}`}>
            <Grid container spacing={2} className={classes.grid}>
                {Filters ? <><Grid item xs={12} md={8}>
                    {Results}
                </Grid>
                <Grid item xs={12} md={4}>
                    {Filters}
                </Grid></> : <Grid item xs={12}>
                    {Results}
                </Grid>}
            </Grid>
        </Box>
        {Navigator && <Toolbar className={classes.toolbar}>
            {Navigator}
        </Toolbar>}
    </div>
};

const SearchPage = () => {
    const params = useParams();
    const {facets,page} = params;
    const solrQueryUrl = getSolrQueryUrl(params);
    configure({cache:false})
    const [{ data, loading, error }] = useAxios(solrQueryUrl);
    if(error) return <SearchError error={error} />;
    if(loading || !data) return <Template
        Navigator={<ResultsNavigatorLoader {...{numOfPages:page,currentPage:page}}/>}
        Results={<ResultsListLoader />}
        Filters={<FacetsPanels />}
    />;
    const {response} = data;
    const {docs,numFound,start} = response || {};
    if(numFound === 0) return <Template
        Results={<ZeroResults />}
        Filters={facets ? <FacetsPanels /> : null}
    />;
    return <Template
        Navigator={<ResultsNavigator start={start} numFound={numFound} numOfDocs={docs.length} />}
        Results={<ResultsList docs={docs} />}
        Filters={<FacetsPanels />}
    />;
};

export default SearchPage;
