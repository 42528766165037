import React from "react";
import { useTranslation } from 'react-i18next'
import useAxios, {configure} from 'axios-hooks'
import { useParams } from 'react-router-dom';
import {makeStyles} from "@material-ui/core";
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import {PdfIcon, DocIcon, ImageIcon} from '../assets/ExtraIcons';
import {DataReadError} from "./StaticPages"
import Skeleton from "@material-ui/lab/Skeleton";
import { Title, Body } from './StaticPages';
import {getSolrQueryUrl} from "../SearchService";
import PrintError from '../components/PrintError';
import ResultsList, {Loader as ResultsListLoader} from "../components/ResultsList";
import ResultsNavigator, {Loader as ResultsNavigatorLoader} from "../components/ResultsNavigator";
import Markdown from 'react-markdown';
import {isDev} from '../IsDev';
import PrintJson from '../components/PrintJson';
import DownloadIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { v4 as uuidv4 } from 'uuid';

const uuid = uuidv4();
const useStyles = makeStyles(theme => ({
    pp: {
        "& p": {
            margin: 0,
        },
        "& p + p": {
            margin: "0.5em 0 0 0",
        },
    },
    chip: {
        margin: "0 8px 8px 0",
        textTransform: "uppercase",
        maxWidth: "calc(100% - 8px)",
    },
    publications: {
        clear: "left",
        backgroundColor: theme.palette.background.default,
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(-4),
        marginBottom: theme.spacing(4),
        marginLeft: theme.spacing(-4),
        padding: theme.spacing(4),
        "& h3": {
            marginBottom: theme.spacing(2),
        },
    },
    toolbar: {
        display: "block",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: "flex",
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    avatar: {
        margin:"0px 16px 16px 0",
        height:150,
        width:150,
        [theme.breakpoints.up('md')]: {
            float:"left",
        }
    },
    aroundAvatar: {
        [theme.breakpoints.up('md')]: {
            marginLeft: 166,
        }
    }
}));

const estractData = (data) => {
    return data.reduce((acc,item) => {
        if(!acc[item.type]) {
            acc[item.type] = [];
        }
        acc[item.type].push(item.value);
        return acc;
    },{});
}

const Publications = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const params = useParams();
    const solrQueryUrl = getSolrQueryUrl(params);
    configure({cache:false})
    const [{ data, loading, error }] = useAxios(solrQueryUrl);
    if(error) return (
        <Box className={classes.publications}>
            <Typography component="h2" variant="h4">{t("Publications")}</Typography>
            <Typography>{t("Search error")}</Typography>
            <Typography variant="subtitle1">{t("An error occurred while performing the search")}</Typography>
            <Typography component="p">{t("The system may be too busy or there may be network problems")}.</Typography>
            <Typography component="p">{t("If the error is caused by problems within the system, we are probably already working on it")}.</Typography>
            <PrintError {...{error}} />
        </Box>
    );
    if(loading || !data) return (
        <Box className={classes.publications}>
            <Typography component="h2" variant="h4"><Skeleton width={"6em"} /></Typography>
            <Toolbar className={classes.toolbar}><ResultsNavigatorLoader /></Toolbar>
            <ResultsListLoader />
            <Toolbar className={classes.toolbar}><ResultsNavigatorLoader /></Toolbar>
        </Box>
    );
    const {response} = data;
    const {docs,numFound,start} = response || {};
    if(numFound === 0) return null;
    return (
        <Box className={classes.publications}>
            <Typography component="h2" variant="h4">{t("Publications")}</Typography>
            <Toolbar className={classes.toolbar}><ResultsNavigator start={start} numFound={numFound} numOfDocs={docs.length} /></Toolbar>
            <ResultsList docs={docs} />
            <Toolbar className={classes.toolbar}><ResultsNavigator start={start} numFound={numFound} numOfDocs={docs.length} /></Toolbar>
        </Box>
    );
}

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

const Cv = ({documento}) => {
    let Icon = DocIcon;
    const {etichetta, descrizione, filename, lingua, mimetype, url} = documento;
    if (mimetype === 'application/pdf') {
        Icon = PdfIcon;
    }
    if (/^image\//.test(mimetype)) {
        Icon = ImageIcon;
    }
    const secondary = <React.Fragment>
    <Typography component="span" display="block">{descrizione}</Typography>
    <Typography component="span" display="block" variant="caption">{filename} ({lingua}) </Typography>
</React.Fragment>;
    return <ListItemLink href={url} alignItems="flex-start">
        <ListItemAvatar>
            <Avatar>
                <Icon style={{height: "40px", width: "40px"}} />
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary={etichetta} secondary={secondary}/>
        <ListItemSecondaryAction title="Download">
            <IconButton edge="end" aria-label="link" component="a" href={url}>
                <DownloadIcon />
            </IconButton>
        </ListItemSecondaryAction>
    </ListItemLink>;
}

const AuthorViewPage = () => {
    const classes = useStyles();
    const { i18n, t } = useTranslation();
    const currLang = i18n.language;
    const {login} = useParams();
    configure({cache:false});
    const [{ data, loading, error }] = useAxios(`${process.env.REACT_APP_AUTHORPROFILE_ENDPOINT}${login}?uuid=${uuid}`);
    if (error) return <DataReadError {...{error}} />;
    if (loading || !data) return <Body>
        <Title><Skeleton width="50%"/></Title>
        <Skeleton variant="rect" className={classes.avatar} />
        {Array.apply(null, Array(16)).map((item,key) => <Skeleton key={key} className={(key < 8) ? classes.aroundAvatar : ''} style={{marginRight: `${(Math.floor(Math.random() * (4 - 1)) + 1)*10}%`}}/>)}
        <Box className={classes.publications}>
            <Typography component="h2" variant="h4"><Skeleton width={"6em"} /></Typography>
            <Toolbar className={classes.toolbar}><ResultsNavigatorLoader /></Toolbar>
            <ResultsListLoader />
            <Toolbar className={classes.toolbar}><ResultsNavigatorLoader /></Toolbar>
        </Box>
    </Body>;
    const {
        nomecompleto,
        shortdescr,
        shortdescr_eng,
        aree_di_interesse,
        aree_di_interesse_eng,
        sitiweb,
    } = estractData(data?.profilopersona?.MAIN || []);
    const {
        email,
        telefono,
        recapito,
        cellulare,
        pec,
        fax,
        skype,
    } = estractData(data?.profilopersona?.CONTACTS || []);
    const livgenlingua = [
        "Conoscenza base",
        "Conoscenza lavorativa limitata",
        "Conoscenza professionale",
        "Conoscenza professionale completa",
        "Conoscenza madrelingua o bilingue",
    ];
    const elencolingue = data?.elencolingue?.reduce((acc,{descr,codice}) => {
        acc[codice] = descr;
        return acc;
    },[]);
    const livlingua = data?.livlingua?.reduce((acc,{id,code}) => {
        acc[id] = code;
        return acc;
    },[])
    const numOfcertificazioniLingue = !!data?.lingueconsciute?.length ? data?.lingueconsciute.reduce((acc,{lingua_alpha3bibcode,certificazioni}) => {
        return acc + certificazioni.reduce((acc,{id,descr_certif}) => acc + 1,0)
    },0) : 0;
    return (
        <Body>
            <Title>{nomecompleto||login}</Title>
            <Avatar alt={""} className={classes.avatar} variant="square" src={`${process.env.REACT_APP_AUTHORPROFILEIMAGE_ENDPOINT}${login}`} />
            {(!!shortdescr_eng || !!shortdescr) && <Typography component="div" gutterBottom>
                <Markdown>{ currLang === 'en' ? ((!!shortdescr_eng && shortdescr_eng[0]) || (!!shortdescr && shortdescr[0])) : (!!shortdescr && shortdescr[0]) }</Markdown>
            </Typography>}
            {(!!aree_di_interesse_eng || !!aree_di_interesse) && <Typography component="h2" variant="h4" gutterBottom>{t("Areas of interest")}</Typography>}
            {(!!aree_di_interesse_eng || !!aree_di_interesse) && <Typography component="p" gutterBottom>{ currLang === 'en' ? (aree_di_interesse_eng || aree_di_interesse) : aree_di_interesse }</Typography>}
            {(!!sitiweb || !!email || !!telefono || !!cellulare || !!recapito || !!pec || !!fax || !!skype) && <Typography component="h2" variant="h4" gutterBottom style={{clear:"both"}}>{t("Contacts")}</Typography>}
            {(!!sitiweb) && <Typography component="p" gutterBottom>{t("Web site")}: {sitiweb.map((item,key) => <span key={key}>{(key > 0) ? ", ":""}<Link href={item}>{item}</Link></span>)}</Typography>}
            {(!!pec) && <Typography component="p" gutterBottom>{t("PEC")}: {pec.map((item,key) => <span key={key}>{(key > 0) ? ", ":""}<Link href={`mailto:${item}`}>{item}</Link></span>)}</Typography>}
            {(!!email) && <Typography component="p" gutterBottom>{t("E-mail")}: {email.map((item,key) => <span key={key}>{(key > 0) ? ", ":""}<Link href={`mailto:${item}`}>{item}</Link></span>)}</Typography>}
            {(!!skype) && <Typography component="p" gutterBottom>{t("Skype")}: {skype.map((item,key) => <span key={key}>{(key > 0) ? ", ":""}{item}</span>)}</Typography>}
            {(!!telefono || !!cellulare) && <Typography component="p" gutterBottom>{t("Phone")}: {[...(telefono||[]),...(cellulare||[])].map((item,key) => <span key={key}>{(key > 0) ? ", ":""}<Link href={`tel:${item}`}>{item}</Link></span>)}</Typography>}
            {(!!fax) && <Typography component="p" gutterBottom>{t("FAX")}: {fax.map((item,key) => <span key={key}>{(key > 0) ? ", ":""}{item}</span>)}</Typography>}
            {(!!recapito) && <Typography component="p" gutterBottom>{t("Address")}: {recapito.map((item,key) => <span key={key}>{(key > 0) ? ", ":""}{item}</span>)}</Typography>}
            {(!!data?.profilopersona?.INTERNATIONAL_IDS?.length) && <Typography component="h2" variant="h4" gutterBottom>{t("International IDs")}</Typography>}
            {(!!data?.profilopersona?.INTERNATIONAL_IDS?.length) && data.profilopersona.INTERNATIONAL_IDS.map((item,key) => (
                    <Typography key={key} component="p" gutterBottom>
                        {data.internationalidtypes[item.type]}: <Link href={`${data.internationalidurls[item.type]}${item.value}`}>{item.value}</Link>
                    </Typography>
                ))
            }
            {(!!data?.profilopersona?.INDEXES?.length) && <Typography component="h2" variant="h4" gutterBottom>{t("H-Indexes")}</Typography>}
            {(!!data?.profilopersona?.INDEXES?.length) && data.profilopersona.INDEXES.map((item,key) => (
                <Typography key={key} component="p" gutterBottom>
                        {data.indextypes[item.type]}: {item.value}
                    </Typography>
                ))
            }
            {(!!data?.lavori?.length) && <Typography component="h2" variant="h4" gutterBottom>{t("Experience")}</Typography>}
            {(!!data?.lavori?.length) && data?.lavori.map((item, key) => {
                const {
                    posiz_lavorativa,
                    posiz_lavorativa_eng,
                    ente,
                    sito_web_ente,
                    indirizzo_ente,
                    luogo,
                    descr,
                    descr_eng,
                    mese_inizio,
                    anno_inizio,
                    mese_fine,
                    anno_fine,
                } = item
                return (
                    <Box key={key} mb={1}>
                        <Typography component="h4" variant="subtitle1"><strong>
                            { currLang === 'en' ? (posiz_lavorativa_eng || posiz_lavorativa) : posiz_lavorativa }
                            {` ${t("work__at")} `}
                            {!!sito_web_ente ? <Link href={sito_web_ente}>{ente}</Link> : ente} ({indirizzo_ente}{(!!indirizzo_ente && !!luogo) ? " – " : ""}{luogo})
                        </strong></Typography>
                        {(!!anno_inizio && !!anno_fine) && <Typography variant="body2">
                            {!anno_inizio ? t("N/A") : `${mese_inizio}${mese_inizio ? "/" : ""}${anno_inizio}`}
                            {" – "}
                            {!anno_fine ? t(!anno_inizio ? "N/A" : "today") : `${mese_fine}${mese_fine ? "/" : ""}${anno_fine}`}
                        </Typography>}
                        <Typography component="p" variant="body1" gutterBottom>
                            { currLang === 'en' ? (descr_eng || descr) : descr }
                        </Typography>
                    </Box>
                );
            })}
            {(!!data?.attivita?.length) && <Typography component="h2" variant="h4" gutterBottom>{t("Activities")}</Typography>}
            {(!!data?.attivita?.length) && data?.attivita.map((item, key) => {
                const {
                    attivita,
                    tipo_attivita,
                    tipo_attivita_eng,
                    ruolo,
                    ruolo_eng,
                    sito_web_attivita,
                    mese_inizio,
                    anno_inizio,
                    mese_fine,
                    anno_fine,
                    descr,
                    descr_eng,
                } = item
                return (
                    <Box key={key} mb={1}>
                        <Typography component="h4" variant="subtitle1"><strong>{attivita}</strong></Typography>
                        <Typography component="p" variant="body2">{ currLang === 'en' ? (tipo_attivita_eng || tipo_attivita) : (tipo_attivita || tipo_attivita_eng) }</Typography>
                        {(!!anno_inizio && !!anno_fine) && <Typography variant="body2">
                            {!anno_inizio ? t("N/A") : `${mese_inizio}${mese_inizio ? "/" : ""}${anno_inizio}`}
                            {" – "}
                            {!anno_fine ? t(!anno_inizio ? "N/A" : "today") : `${mese_fine}${mese_fine ? "/" : ""}${anno_fine}`}
                        </Typography>}
                        {(!!ruolo_eng || !!ruolo) && <Typography component="p" variant="body1"><strong>{t("Role")}:</strong> { currLang === 'en' ? (ruolo_eng || ruolo) : ruolo }</Typography>}
                        {!!sito_web_attivita && <Typography component="p" variant="body1"><strong>{t("Web site")}:</strong> <Link href={sito_web_attivita}>{sito_web_attivita}</Link></Typography>}
                        {(!!ruolo_eng || !!ruolo) && <Typography component="div" variant="body1" className={classes.pp}>
                            <Markdown>
                                { currLang === 'en' ? (descr_eng || descr) : (descr || descr_eng) }
                            </Markdown>
                        </Typography>}
                    </Box>
                );
            })}
            {(!!data?.formazione?.length) && <Typography component="h2" variant="h4" gutterBottom>{t("Education")}</Typography>}
            {(!!data?.formazione?.length) && data?.formazione.map((item, key) => {
                const {
                    titolo,
                    ente,
                    data_conseguimento,
                    voto_conseg,
                    voto_max,
                    lode,
                    titolo_tesi,
                } = item
                return (
                    <Box key={key} mb={1}>
                        {!!titolo && <Typography component="h4" variant="subtitle1"><strong>{titolo}</strong></Typography>}
                        {!!ente && <Typography component="p" variant="body1"><strong>{ ente }</strong></Typography>}
                        {!!data_conseguimento && <Typography component="p" variant="body1"><strong>{t("Graduation date")}:</strong> { data_conseguimento }</Typography>}
                        {!!voto_conseg && <Typography component="p" variant="body1"><strong>{t("Grade")}:</strong> { `${voto_conseg}${!!voto_max ? "/":""}${voto_max}${!!(lode*1)? " cum Laude" : ""}` }</Typography>}
                        {!!titolo_tesi && <Typography component="p" variant="body1"><strong>{t("Thesis")}:</strong> { titolo_tesi }</Typography>}
                    </Box>
                );
            })}
            {(!!data?.lingueconsciute?.length) && <Typography component="h2" variant="h4" gutterBottom>{t("Languages")}</Typography>}
            {(!!data?.lingueconsciute?.length) && <TableContainer component={Box} mb={2}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell rowSpan={2}></TableCell>
                            <TableCell colSpan={2}>{t("Production")}</TableCell>
                            <TableCell colSpan={2}>{t("Understanding")}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><CreateIcon /></TableCell>
                            <TableCell><ChatBubbleIcon /></TableCell>
                            <TableCell><CreateIcon /></TableCell>
                            <TableCell><ChatBubbleIcon /></TableCell>
                            <TableCell>{t("Notes")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.lingueconsciute.map((item, key) => {
                            const {
                                lingua_alpha3bibcode,
                                id_livello_generale,
                                id_livello_prod_scritta,
                                id_livello_prod_orale,
                                id_livello_compr_scritta,
                                id_livello_compr_orale,
                                note,
                                note_eng,
                            } = item;
                            return (
                                <TableRow key={key}>
                                    <TableCell>
                                        <strong>{elencolingue[lingua_alpha3bibcode]}</strong>
                                        <br />
                                        {livgenlingua[id_livello_generale - 1]}
                                    </TableCell>
                                    <TableCell>{livlingua[id_livello_prod_scritta]}</TableCell>
                                    <TableCell>{livlingua[id_livello_prod_orale]}</TableCell>
                                    <TableCell>{livlingua[id_livello_compr_scritta]}</TableCell>
                                    <TableCell>{livlingua[id_livello_compr_orale]}</TableCell>
                                    <TableCell>{ currLang === 'en' ? note_eng : note }</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>}
            {!!numOfcertificazioniLingue && <Typography mt={2}><strong>{t("Language certifications")}</strong></Typography>}
            {!!numOfcertificazioniLingue && <TableContainer component={Box} mb={2}>
                <Table>
                    <TableBody>
                        {(!!data?.lingueconsciute?.length) && data?.lingueconsciute.map(({lingua_alpha3bibcode,certificazioni}, key) => {
                            return certificazioni.map(({id,descr_certif},key) => (
                                <TableRow key={id}>
                                    <TableCell><strong>{elencolingue[lingua_alpha3bibcode]}</strong></TableCell>
                                    <TableCell>{descr_certif}</TableCell>
                                </TableRow>
                            ))
                        })}
                    </TableBody>
                </Table>
            </TableContainer>}
            {(!!data?.competenze?.length) && <Typography component="h2" variant="h4" gutterBottom>{t("Skills")}</Typography>}
            {(!!data?.competenze?.length) && data?.competenze.map(({descr}, key) => <Chip key={key} label={descr} className={classes.chip} />)}
            {(!!data?.classifateco?.length) && <Typography component="h2" variant="h4" gutterBottom>{t("Research activities classification ATECO")}</Typography>}
            {(!!data?.classifateco?.length) && data?.classifateco.map(({name}, key) => <Chip key={key} label={name} className={classes.chip} />)}
            {(!!data?.classiferc?.length) && <Typography component="h2" variant="h4" gutterBottom>{t("Research activities classification ERC")}</Typography>}
            {(!!data?.classiferc?.length) && data?.classiferc.map(({name}, key) => <Chip key={key} label={name} className={classes.chip} />)}
            {(!!data?.profilopersona?.CV?.length) && <Typography component="h2" variant="h4" gutterBottom>{t("Curriculum Vitae")}</Typography>}
            {(!!data?.profilopersona?.CV?.length) && <List>
                {(!!data?.profilopersona?.CV?.length) && data?.profilopersona?.CV.map((cv, key) => <Cv key={key} documento={cv} />)}
            </List>}
            <Publications />
            {isDev && <PrintJson data={data} />}
        </Body>
    );
};

export default AuthorViewPage;
