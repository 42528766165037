import React from "react";
const PrintError = ({error,children}) => {
    const e = !!error ? error : children
    return (
        <>
            <pre style={{whiteSpace:"pre-wrap",color:"rgba(0,0,0,0.3)"}}>{e?.toString()}</pre>
            <pre style={{whiteSpace:"pre-wrap",color:"rgba(0,0,0,0.6)"}}>{e?.response?.data?.error?.msg}</pre>
        </>
    )
};
export default PrintError;