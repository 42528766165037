import React from 'react';
import { useTranslation } from "react-i18next";
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { Toolbar, AppBar } from '@material-ui/core';
import SetLang from './SetLang';
import {menuItemsConfig} from "./ApplicationBar";

const HamburgerMenu = () => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const toggle = ev => {
        ev.preventDefault();
        setOpen(!open);
    }
    return (
        <React.Fragment>
            <React.Fragment>
                <IconButton onClick={toggle} color="inherit"><MenuIcon /></IconButton>
            </React.Fragment>
            <Drawer anchor="left" open={open} onClose={toggle}>
                <div style={{width:"80vw"}}>
                    <AppBar elevation={0} position={"static"}>
                        <Toolbar>
                            <IconButton color="inherit" onClick={toggle}><CloseIcon /></IconButton>
                            <Button color="inherit" style={{paddingLeft:0}} onClick={toggle}>{t("CLOSE MENU")}</Button>
                            <span style={{flex:1}} />
                            <SetLang />
                        </Toolbar>
                    </AppBar>
                    <List>
                        <ListItem button component="a" href="/"><ListItemText primary="Home"/></ListItem>
                        {menuItemsConfig.map(({title,href},key) => <ListItem key={key} button component="a" href={href}><ListItemText primary={t(title)}/></ListItem>)}
                    </List>
                </div>
            </Drawer>
      </React.Fragment>
    );
}

export default HamburgerMenu;