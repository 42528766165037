import React from 'react';
import { makeStyles } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import ReportProblemTwoToneIcon from '@material-ui/icons/ReportProblemTwoTone';
import useAxios, {configure} from 'axios-hooks'
import { getSolrQueryUrl } from '../SearchService';
import { DocumentData, DocumentDataLoader } from "../components/DocumentListItem";
import HomeCover from '../components/HomeCover';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    box: {
        padding: theme.spacing(2, 2, 2, 2),
        border: "1px solid rgba(0,0,0,0.2)",
        borderRadius: theme.spacing(0.5),
        background: theme.palette.background.default,
    },
}));

const BoxNewRecords = ({rows}) => {
    rows = rows || 6
    const classes = useStyles();
    const { t } = useTranslation();
    const sort = "creazione_s-i-s desc";
    const solrQueryUrl = getSolrQueryUrl({...{rows, sort}});
    configure({cache:false})
    const [{ data, loading, error }] = useAxios(solrQueryUrl);
    if(error) return (
        <>
            <Typography variant="h5" component="h2" gutterBottom>{t("Last publications archived")}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}><div className={classes.box}>
                    <ReportProblemTwoToneIcon style={{opacity:0.5,fontSize:150,margin:"0 auto",display:"block"}}/>
                    <p style={{opacity:0.5,margin:"0 0 1em 0", textAlign: "center"}}>{t("Loading error")}</p>
                </div></Grid>
                <Grid item xs={12} md={4}><div className={classes.box}>
                    <ReportProblemTwoToneIcon style={{opacity:0.5,fontSize:150,margin:"0 auto",display:"block"}}/>
                    <p style={{opacity:0.5,margin:"0 0 1em 0", textAlign: "center"}}>{t("Loading error")}</p>
                </div></Grid>
                <Grid item xs={12} md={4}><div className={classes.box}>
                    <ReportProblemTwoToneIcon style={{opacity:0.5,fontSize:150,margin:"0 auto",display:"block"}}/>
                    <p style={{opacity:0.5,margin:"0 0 1em 0", textAlign: "center"}}>{t("Loading error")}</p>
                </div></Grid>
            </Grid>
            <Box style={{textAlign:"center"}} pt={2}>
            <Button variant="outlined" component="a" href="search" disabled>{t("All publications")}</Button>
            </Box>
        </>
    );
    if(loading || !data) return (
        <>
            <Typography variant="h5" component="h2" gutterBottom><Skeleton style={{width:"20em",maxWidth:"80%"}} /></Typography>
            <Grid container spacing={2}>
                {Array.apply(null, Array(rows)).map((item,key) => <Grid key={key} item xs={12} md={4}><DocumentDataLoader /></Grid>)}
            </Grid>
            <Box style={{textAlign:"center"}} pt={2}>
            <Button variant="outlined" component="a" href="search" disabled>{t("All publications")}</Button>
            </Box>
        </>
    );
    
    const {response} = data;
    const {docs} = response;
    return (
        <>
            <Typography variant="h4" component="h2" gutterBottom>{t("Last publications archived")}</Typography>
            <Grid container spacing={4} style={{display:"flex"}}>
                {docs.map((doc,key) => <Grid key={key} item xs={12} md={4} style={{display:"flex"}}><DocumentData doc={doc} /></Grid>)}
            </Grid>
            <Box style={{textAlign:"center"}} pt={2}>
                <Button variant="outlined" component="a" href="search">{t("All publications")}</Button>
            </Box>
        </>
    );
}

const Homepage = () => {
    return (
        <>
            <HomeCover />
            <div style={{backgroundColor:"#ffffff"}} id="recentPubs">
                    <Box p={2}>
                        <BoxNewRecords rows={6} />
                    </Box>
            </div>
        </>
    );
};

export default Homepage;
