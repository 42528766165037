import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import useAxios, {configure} from 'axios-hooks'
import { useHistory } from 'react-router-dom';
import {fade, makeStyles} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from "@material-ui/lab/Skeleton";
import PrintError from '../components/PrintError';
import { getSolrQueryUrlForFacet } from '../SearchService';
import {AutoreCNR,ListItemWithAvatarLoader} from '../pages/AssetViewPage';
import {parsedContains} from '../Utils';
const useStyles = makeStyles(theme => ({
    flex: {
        display: "flex",
        flexFlow: "column",
        flexGrow: 1
    },
    header: {
        background: theme.palette.primary.light,
        marginBottom: "-10rem",
        height: "10rem", 
    },
    appbar: {
        background: theme.palette.primary.light,
    },
    navigator: {
        [theme.breakpoints.up('md')]: {
            display: "flex",
        }
    },
    li: {
        [theme.breakpoints.up('md')]: {
            display: "flex",
            flexWrap: "wrap",
            "& > *": {
                flex: "0 0 50%"
            }
        }
    },
    form: {
        flex: "1 1 auto",
        marginBottom: theme.spacing(2),
        "& > div": {
            [theme.breakpoints.up('md')]: {
                display: "flex",
                alignItems: "center",
            }
        }
    },
    search: {
        flex: 1,
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.black, 0.08),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.12),
        },
        marginLeft: 0,
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        display: 'flex',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        width: '100%',
    },
    orderby: {
        whiteSpace: "nowrap",
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        }
    },
    notSelected: {
        fontWeight: "normal",
    }
}));

const NoAuthors = ({filter}) => {
    const {t} = useTranslation();
    return <Box>
        <Box mb={3}>
            <Typography variant="h3" component="h1" gutterBottom color="textSecondary">
                <div style={{fontSize: "5em",whiteSpace:"nowrap"}}>(-_-)</div>
            </Typography>
        </Box>
        <Typography variant="h2" component="h2" gutterBottom>
            {t("No results found")}
        </Typography>
        {!!filter && <Typography>
            {t("Check for typing errors in search terms")}: <strong>{filter}</strong>
        </Typography>}
    </Box>
};

const AuthorsPage = () => {
    const { t } = useTranslation();
    const facet = "autoricnr_s-i-s-m";
    const limit = 50;
    const classes = useStyles();
    const history = useHistory();
    const [page,setPage] = useState(1);
    const [filter,setFilter] = useState("");
    const [sort,setSort] = useState("index");
    const offset = (page - 1) * limit;
    const goBack = () => history.go(-1);
    configure({cache:false});
    const solrQueryUrl = getSolrQueryUrlForFacet({
        facet,
        query: "*:*",
        facets: null,
        limit,
        offset: 0,
        // filter,
        sort
    });
    const [{ data, loading, error }] = useAxios(solrQueryUrl);
    const buckets = (data?.facet_counts?.facet_fields[facet] || []).reduce((acc,item,i) => {
        if(i % 2 === 0) {
            acc.push({label:item});
        } else {
            acc[acc.length-1] = {
                val: acc[acc.length-1].label,
                count: item
            };  
        }
        return acc;
    },[]).reduce((acc,item) => {
        // pulizia valori non desiderati
        if(!/^([-._\s]+|\[object Object\]| \$::\$)$|^$/.test(item.val) && parsedContains(item.val, filter)) {
            acc.push(item);
        }
        return acc;
    },[]);
    const distinctCount = buckets.length;
    const numOfPages = Math.ceil(distinctCount/limit);
    const authors = buckets.slice(offset,page*limit).map(item => {
        const fullname = item.val.replace(/\$:(.+):\$$/,"");
        const login = item.val.replace(/.+\$:(.+):\$$/,"$1");
        const avatarUrl = `${process.env.REACT_APP_AUTHORPROFILEIMAGE_ENDPOINT}${login}`;
        return {...item, fullname, login, avatarUrl}
    });
    const from = authors.length > 0 ? ((page - 1)*limit + 1) : 0;
    const to = authors.length > 0 ? authors.length + from - 1 : 0;
    let searchInput = React.createRef();
    useEffect(() => {
        setPage(1);
    }, [filter]);

    return <Box pb={2}>
        <AppBar position="static" elevation={0} className={classes.appbar}>
            <Toolbar>
                <IconButton color="inherit" onClick={goBack}>
                    <ArrowBackIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
        <div className={classes.header}></div>
        <Container>
            <Paper>
                <Box p={4}>
                    <Typography component="h1" variant="h2" gutterBottom>{t("Authors")}</Typography>
                    <form className={classes.form} onSubmit={(ev)=>{
                        ev.preventDefault();
                        setFilter(searchInput.value);
                    }}>
                        <div>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    disabled={loading || !!error}
                                    placeholder={`${t("Filter")} ...`}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    defaultValue={filter}
                                    name='query'
                                    autoComplete='off'
                                    inputRef={el => searchInput = el}
                                />
                            </div>
                            <Typography className={classes.orderby}>{t("Order by")}:
                                <Button className={sort === "index" ? undefined : classes.notSelected} onClick={()=>setSort("index")}>{t("Full name")}</Button> | <Button className={sort === "count" ? undefined : classes.notSelected} onClick={()=>setSort("count")}>{t("Count")}</Button>
                            </Typography>
                        </div>
                    </form>
                    <div className={classes.navigator}>
                        {loading ? <Skeleton className={classes.inlineTextSkeleton} width={"17em"} /> : <Typography variant="subtitle1">{t("results_from_to_of",{from, to, of: distinctCount})}</Typography>}
                        <span style={{flex: "1 0 auto"}}/>
                        {(loading || !!error) ? <Pagination siblingCount={1} boundaryCount={1} count={10} page={0} disabled={true} /> : <Pagination siblingCount={1} boundaryCount={1} count={numOfPages} page={page} onChange={(event,value) => {setPage(value)}} />}
                    </div>
                    {!!error && <PrintError>{error}</PrintError>}
                    {!error && <List dense={true} className={classes.li}>
                        {!!loading && Array.apply(null, Array(limit)).map((item,key) => <ListItemWithAvatarLoader key={key} />)}
                        {!!data && authors.map((autore,key)=><AutoreCNR key={key} autore={autore} />)}
                        {!!data && authors?.length === 0 && <NoAuthors {...{filter}}/>}
                    </List>}
                    {(loading || !!error) ? <Pagination siblingCount={1} boundaryCount={1} count={10} page={0} disabled={true} /> : <Pagination siblingCount={1} boundaryCount={1} count={numOfPages} page={page} onChange={(event,value) => {setPage(value)}} />}
                </Box>
            </Paper>
        </Container>
    </Box>;
};

export default AuthorsPage;
