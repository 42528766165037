import React from 'react';
import {
    Box,
    Paper,
    Typography,
    Container,
    makeStyles,
    AppBar,
    Toolbar,
    IconButton
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Markdown from 'react-markdown';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import PrintError from '../components/PrintError';

const useStyles = makeStyles(theme => ({
    header: {
        background: theme.palette.primary.light,
        marginBottom: "-10rem",
        height: "10rem",
    },
    appbar: {
        background: theme.palette.primary.light,
    },
}));

const TestoRiempitivo = () => <>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et ultrices augue. Vivamus ac pulvinar mi, vehicula aliquet tellus. Donec nec nibh tempus, venenatis orci convallis, condimentum sem. Praesent augue mauris, vestibulum eget luctus nec, dapibus et neque. Suspendisse potenti. Etiam porta magna vitae turpis hendrerit viverra. Etiam ut massa commodo, hendrerit neque quis, pulvinar leo.</p>
    <p>Phasellus ultrices erat nec justo venenatis suscipit. Suspendisse vel purus tincidunt, faucibus tellus ut, rhoncus enim. Proin egestas, risus sit amet hendrerit fringilla, nisi enim rutrum eros, eu posuere nibh eros ut enim. Phasellus tempus quam efficitur risus iaculis, interdum volutpat eros euismod. Ut quis rhoncus massa. Vivamus malesuada ante libero, et vulputate erat cursus eget. Suspendisse hendrerit metus a ipsum rutrum malesuada.</p>
    <p>Donec rutrum magna risus, non varius urna condimentum sed. Proin velit diam, lacinia at blandit vel, porttitor quis justo. Etiam volutpat mattis rhoncus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec feugiat in dolor in condimentum. Mauris a libero ac quam pellentesque dignissim id nec elit. Nulla pellentesque ut lectus sed mattis. Curabitur vitae augue luctus, tincidunt quam sed, pretium urna. Curabitur semper dui magna, vel aliquet orci accumsan sit amet. Proin sed tellus sed sem rutrum placerat. Sed tincidunt enim mi, sit amet suscipit risus dignissim ut.</p>
</>;

export const Title = (props) => <Typography {...props} component="h1" variant="h2" gutterBottom>{props.children}</Typography>;

export const Body = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const goBack = () => history.go(-1);
    return (
        <Box {...props} pb={2}>
            <AppBar position="static" elevation={0} className={classes.appbar}>
                <Toolbar>
                    <IconButton color="inherit" onClick={goBack}>
                        <ArrowBackIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={classes.header}></div>
            <Container>
                <Paper>
                    <Box p={4}>
                        {props.children}
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
}
export const About = () => {
    const { t } = useTranslation();
    return (
        <Body>
            <Title>{t("About")}</Title>
            <Markdown>{t("intro__p0")}</Markdown>
            <Markdown>{t("intro__p1")}</Markdown>
            <Markdown>{t("intro__p2")}</Markdown>
            <Typography id="intro__subt1" component="h3" variant="h4" gutterBottom>{t("intro__subt1")}</Typography>
            <Markdown>{t("intro__p3")}</Markdown>
            <Markdown>{t("intro__p4")}</Markdown>
            <Typography id="intro__subt2" component="h3" variant="h4" gutterBottom>{t("intro__subt2")}</Typography>
            <Markdown>{t("intro__p5")}</Markdown>
        </Body>
    );
};

export const Guidelines = () => {
    const { t } = useTranslation();
    return (
        <Body>
            <Title>{t("Guidelines")}</Title>
            <Markdown>{t("guidelines__p1")}</Markdown>
            <Markdown>{t("guidelines__p2")}</Markdown>
            <Markdown>{t("guidelines__p3")}</Markdown>
        </Body>
    );
};

export const Developers = () => {
    const { t } = useTranslation();
    const spec = {
        "openapi": "3.0.1",
        "servers": [
            {
                "url": "https://publications.cnr.it/api/v1"
            }
        ],
        // "security": [
        //     {
        //         "basicAuth": []
        //     }
        // ],
        "tags": [
            {
                "name": "publication",
                "description": "Publication's data"
            },
            {
                "name": "author",
                "description": "Author's profile"
            }
        ],
        "paths": {
            "/search": {
                "get": {
                    "tags": [
                        "publication"
                    ],
                    "summary": "Find publication metadata by Solr query",
                    "description": "Returns Solr result",
                    "operationId": "FindPublications",
                    "parameters": [
                        {
                            "name": "q",
                            "in": "query",
                            "description": `Solr query: try use "*:*"`,
                            "required": true,
                            "schema": {
                                "type": "string"
                            }
                        },
                        {
                            "name": "rows",
                            "in": "query",
                            "description": `Number of rows per page`,
                            "required": false,
                            "schema": {
                                "type": "string"
                            }
                        },
                        {
                            "name": "start",
                            "in": "query",
                            "description": `Absolute "offset" in the complete sorted list of matches that the client wants Solr to use as the beginning of the current "page"`,
                            "required": false,
                            "schema": {
                                "type": "string"
                            }
                        },
                        {
                            "name": "sort",
                            "in": "query",
                            "description": `The sort parameter arranges search results in either ascending (asc) or descending (desc) order`,
                            "required": false,
                            "schema": {
                                "type": "string"
                            }
                        },
                        {
                            "name": "wt",
                            "in": "query",
                            "description": "Response format (json, xml)",
                            "required": false,
                            "schema": {
                                "type": "string",
                                "enum": ["json","xml","csv"]
                            }
                        }

                    ],
                    "responses": {
                        "200": {
                            "description": "successful operation",
                            "content": {}
                        },
                        "400": {
                            "description": "Invalid query supplied",
                            "content": {}
                        },
                    }
                }
            },
            "/publication/{id}": {
                "get": {
                    "tags": [
                        "publication"
                    ],
                    "summary": "Find publication metadata by ID",
                    "description": "Returns a single publication as OAI-PMH record in oai_dc format",
                    "operationId": "getPublicationMetadataByLogin",
                    "parameters": [
                        {
                            "name": "id",
                            "in": "path",
                            "description": "ID of publication to return",
                            "required": true,
                            "schema": {
                                "type": "string"
                            }
                        }
                    ],
                    "responses": {
                        "200": {
                            "description": "successful operation",
                            "content": {}
                        },
                        "400": {
                            "description": "Invalid ID supplied",
                            "content": {}
                        },
                        "404": {
                            "description": "ID not found",
                            "content": {}
                        }
                    }
                }
            },
            "/author/profile/{login}": {
                "get": {
                    "tags": [
                        "author"
                    ],
                    "summary": "Find author profile by LOGIN",
                    "description": "Returns a single author profile",
                    "operationId": "getAuthorProfileByLogin",
                    "parameters": [
                        {
                            "name": "login",
                            "in": "path",
                            "description": "LOGIN of author to return",
                            "required": true,
                            "schema": {
                                "type": "string"
                            }
                        }
                    ],
                    "responses": {
                        "200": {
                            "description": "successful operation",
                            "content": {}
                        },
                        "400": {
                            "description": "Invalid LOGIN supplied",
                            "content": {}
                        },
                        "404": {
                            "description": "LOGIN not found",
                            "content": {}
                        }
                    }
                }
            },
            "/author/image/{login}": {
                "get": {
                    "tags": [
                        "author"
                    ],
                    "summary": "Find author image by LOGIN",
                    "description": "Returns a single author image",
                    "operationId": "getAuthorImageByLogin",
                    "parameters": [
                        {
                            "name": "login",
                            "in": "path",
                            "description": "LOGIN of author to return",
                            "required": true,
                            "schema": {
                                "type": "string"
                            }
                        }
                    ],
                    "responses": {
                        "200": {
                            "description": "successful operation",
                            "content": {}
                        },
                        "400": {
                            "description": "Invalid LOGIN supplied",
                            "content": {}
                        },
                        "404": {
                            "description": "LOGIN not found",
                            "content": {}
                        }
                    }
                }
            }
        },
        // "components": {
        //     "securitySchemes": {
        //         "basicAuth": {
        //             "type": "http",
        //             "scheme": "basic"
        //         }
        //     }
        // }
    };
    return (
        <Body>
            <Title>{t("Developers")}</Title>
            <Markdown>{t("developers__p1")}</Markdown>
            <Typography id="api-rest" component="h3" variant="h4" gutterBottom>{t("API REST")}</Typography>
            <Markdown>{t("apirest__p1")}</Markdown>
            <SwaggerUI spec={spec} />
            <Typography id="oai-pmh" component="h3" variant="h4" gutterBottom>{t("OAI-PMH")}</Typography>
            <Markdown>{t("oaipmh__p1")}</Markdown>
            <Markdown>{t("oaipmh__p2")}</Markdown>
            <Markdown>{t("oaipmh__p3")}</Markdown>
            <Markdown>{t("oaipmh__p4")}</Markdown>
            <Markdown>{t("oaipmh__p5")}</Markdown>
        </Body>
    );
};

export const Instructions = () => <Body>
    <Title>{useTranslation().t("Instructions")}</Title>
    <TestoRiempitivo />
</Body>;

export const Contacts = () => <Body>
    <Title>{useTranslation().t("Contacts")}</Title>
    <Markdown>[oapublications@cnr.it](mailto:oapublications@cnr.it)</Markdown>
</Body>;

export const DataReadError = ({ error }) => {
    const { t } = useTranslation();
    return (<Body>
        <Title>{t("Data read error")}</Title>
        <Typography variant="subtitle1">{t("An error occurred while reading record data")}</Typography>
        <Typography variant="h3" gutterBottom color="textSecondary">
            <div style={{ fontSize: "5em", whiteSpace: "nowrap", marginBottom: 64 }}>(×_×)</div>
        </Typography>
        <Typography component="p">{t("The system may be too busy or there may be network problems")}.</Typography>
        <Typography component="p">{t("If the error is caused by problems within the system, we are probably already working on it")}.</Typography>
        <PrintError {...{ error }} />
    </Body>);
}

export const SearchError = ({ error }) => {
    const { t } = useTranslation();
    return (<Body>
        <Title>{t("Search error")}</Title>
        <Typography variant="subtitle1">{t("An error occurred while performing the search")}</Typography>
        <Typography variant="h3" gutterBottom color="textSecondary">
            <div style={{ fontSize: "5em", whiteSpace: "nowrap", marginBottom: 64 }}>(×_×)</div>
        </Typography>
        <Typography component="p">{t("The system may be too busy or there may be network problems")}.</Typography>
        <Typography component="p">{t("If the error is caused by problems within the system, we are probably already working on it")}.</Typography>
        <PrintError {...{ error }} />
    </Body>);
}

export const Error404 = () => {
    const { t } = useTranslation();
    return (<Body>
        <Title>{useTranslation().t("Error 404")}</Title>
        <Typography variant="subtitle1">{t("We couldn't find that page")}</Typography>
        <Typography variant="h3" gutterBottom color="textSecondary">
            <div style={{ fontSize: "5em", whiteSpace: "nowrap", marginBottom: 64 }}>(×_×)</div>
        </Typography>
    </Body>);
}