export const Languages = [{
    "descr": "Lingue australiane",
    "codice": "aus",
}, {
    "descr": "abcaso",
    "codice": "abk",
}, {
    "descr": "accado",
    "codice": "akk",
}, {
    "descr": "accinese",
    "codice": "ace",
}, {
    "descr": "acioli",
    "codice": "ach",
}, {
    "descr": "adangme",
    "codice": "ada",
}, {
    "descr": "afar",
    "codice": "aar",
}, {
    "descr": "afrihili",
    "codice": "afh",
}, {
    "descr": "afrikaans",
    "codice": "afr",
}, {
    "descr": "afro-asiatica (altra lingua)",
    "codice": "afa",
}, {
    "descr": "akan",
    "codice": "aka",
}, {
    "descr": "albanese",
    "codice": "alb",
}, {
    "descr": "aleuto",
    "codice": "ale",
}, {
    "descr": "altaica (altra lingua)",
    "codice": "tut",
}, {
    "descr": "amarico",
    "codice": "amh",
}, {
    "descr": "anglosassone (450-1100)",
    "codice": "ang",
}, {
    "descr": "apache",
    "codice": "apa",
}, {
    "descr": "arabo",
    "codice": "ara",
}, {
    "descr": "aramaico",
    "codice": "arc",
}, {
    "descr": "arapaho",
    "codice": "arp",
}, {
    "descr": "araucano",
    "codice": "arn",
}, {
    "descr": "armeno",
    "codice": "arm",
}, {
    "descr": "artificiale (altra lingua)",
    "codice": "art",
}, {
    "descr": "aruaco",
    "codice": "arw",
}, {
    "descr": "assamese",
    "codice": "asm",
}, {
    "descr": "austronesiana (altra lingua)",
    "codice": "map",
}, {
    "descr": "avaro",
    "codice": "ava",
}, {
    "descr": "avestico",
    "codice": "ave",
}, {
    "descr": "awadhi",
    "codice": "awa",
}, {
    "descr": "aymarà",
    "codice": "aym",
}, {
    "descr": "azerbaigiano",
    "codice": "aze",
}, {
    "descr": "balinese",
    "codice": "ban",
}, {
    "descr": "baltica (altra lingua)",
    "codice": "bat",
}, {
    "descr": "bambara",
    "codice": "bam",
}, {
    "descr": "banda",
    "codice": "bad",
}, {
    "descr": "bantu (altra lingua)",
    "codice": "bnt",
}, {
    "descr": "basa",
    "codice": "bas",
}, {
    "descr": "baschiro",
    "codice": "bak",
}, {
    "descr": "basco",
    "codice": "baq",
}, {
    "descr": "batak (indonesia)",
    "codice": "btk",
}, {
    "descr": "begia",
    "codice": "bej",
}, {
    "descr": "beluci",
    "codice": "bal",
}, {
    "descr": "bemba",
    "codice": "bem",
}, {
    "descr": "bengali",
    "codice": "ben",
}, {
    "descr": "bhojpuri",
    "codice": "bho",
}, {
    "descr": "bicol",
    "codice": "bik",
}, {
    "descr": "bielorusso",
    "codice": "bel",
}, {
    "descr": "bihari",
    "codice": "bih",
}, {
    "descr": "bini",
    "codice": "bin",
}, {
    "descr": "birmano",
    "codice": "bur",
}, {
    "descr": "bislama",
    "codice": "bis",
}, {
    "descr": "braj",
    "codice": "bra",
}, {
    "descr": "bretone",
    "codice": "bre",
}, {
    "descr": "bugi",
    "codice": "bug",
}, {
    "descr": "bulgaro",
    "codice": "bul",
}, {
    "descr": "buriato",
    "codice": "bua",
}, {
    "descr": "cabilo",
    "codice": "kab",
}, {
    "descr": "caddo",
    "codice": "cad",
}, {
    "descr": "canarese",
    "codice": "kan",
}, {
    "descr": "caribico",
    "codice": "car",
}, {
    "descr": "catalano",
    "codice": "cat",
}, {
    "descr": "caucasica (altra lingua)",
    "codice": "cau",
}, {
    "descr": "cebuano",
    "codice": "ceb",
}, {
    "descr": "ceceno",
    "codice": "che",
}, {
    "descr": "ceco",
    "codice": "cze",
}, {
    "descr": "celtico (altra lingua)",
    "codice": "cel",
}, {
    "descr": "chamorro",
    "codice": "cha",
}, {
    "descr": "cherokee",
    "codice": "chr",
}, {
    "descr": "cheyenne",
    "codice": "chy",
}, {
    "descr": "chibcha",
    "codice": "chb",
}, {
    "descr": "chinook",
    "codice": "chn",
}, {
    "descr": "chipewyan",
    "codice": "chp",
}, {
    "descr": "chirghiso",
    "codice": "kir",
}, {
    "descr": "choctaw",
    "codice": "cho",
}, {
    "descr": "chuukese",
    "codice": "chk",
}, {
    "descr": "ci",
    "codice": "twi",
}, {
    "descr": "ciagataico",
    "codice": "chg",
}, {
    "descr": "ciami (lingue)",
    "codice": "cmc",
}, {
    "descr": "cinese",
    "codice": "chi",
}, {
    "descr": "ciuvasce",
    "codice": "chv",
}, {
    "descr": "congolese",
    "codice": "kon",
}, {
    "descr": "copto",
    "codice": "cop",
}, {
    "descr": "coreano",
    "codice": "kor",
}, {
    "descr": "cornico",
    "codice": "cor",
}, {
    "descr": "corso",
    "codice": "cos",
}, {
    "descr": "cosacco",
    "codice": "kaz",
}, {
    "descr": "cosraeano (cosreo)",
    "codice": "kos",
}, {
    "descr": "cree",
    "codice": "cre",
}, {
    "descr": "creola (altra lingua)",
    "codice": "crp",
}, {
    "descr": "creolo-francese (altra lingua)",
    "codice": "cpf",
}, {
    "descr": "creolo-inglese (altra lingua)",
    "codice": "cpe",
}, {
    "descr": "creolo-portoghese (altra lingua)",
    "codice": "cpp",
}, {
    "descr": "curdo",
    "codice": "kur",
}, {
    "descr": "cuscitica (altra lingua)",
    "codice": "cus",
}, {
    "descr": "daiaco",
    "codice": "day",
}, {
    "descr": "dakota",
    "codice": "dak",
}, {
    "descr": "danese",
    "codice": "dan",
}, {
    "descr": "delaware",
    "codice": "del",
}, {
    "descr": "dinca",
    "codice": "din",
}, {
    "descr": "diula",
    "codice": "dyu",
}, {
    "descr": "divedi",
    "codice": "div",
}, {
    "descr": "dogri",
    "codice": "doi",
}, {
    "descr": "dogrib",
    "codice": "dgr",
}, {
    "descr": "dravidica (altra lingua)",
    "codice": "dra",
}, {
    "descr": "duala",
    "codice": "dua",
}, {
    "descr": "dzongkha",
    "codice": "dzo",
}, {
    "descr": "efik",
    "codice": "efi",
}, {
    "descr": "egiziano antico",
    "codice": "egy",
}, {
    "descr": "ekajuk",
    "codice": "eka",
}, {
    "descr": "elamitico",
    "codice": "elx",
}, {
    "descr": "esperanto",
    "codice": "epo",
}, {
    "descr": "estone",
    "codice": "est",
}, {
    "descr": "ewe",
    "codice": "ewe",
}, {
    "descr": "ewondo",
    "codice": "ewo",
}, {
    "descr": "fan",
    "codice": "fan",
}, {
    "descr": "fanti",
    "codice": "fat",
}, {
    "descr": "faroese",
    "codice": "fao",
}, {
    "descr": "fenicio",
    "codice": "phn",
}, {
    "descr": "figi",
    "codice": "fij",
}, {
    "descr": "filippina (altra lingua)",
    "codice": "phi",
}, {
    "descr": "finlandese",
    "codice": "fin",
}, {
    "descr": "fon",
    "codice": "fon",
}, {
    "descr": "francese",
    "codice": "fre",
}, {
    "descr": "francese antico (842-1400 ca.)",
    "codice": "fro",
}, {
    "descr": "francese medio (1400-1600 ca.)",
    "codice": "frm",
}, {
    "descr": "frisone",
    "codice": "fry",
}, {
    "descr": "friulano",
    "codice": "fur",
}, {
    "descr": "ful",
    "codice": "ful",
}, {
    "descr": "ga",
    "codice": "gaa",
}, {
    "descr": "gaelico (scozzese)",
    "codice": "gla",
}, {
    "descr": "gallese",
    "codice": "wel",
}, {
    "descr": "gallègo",
    "codice": "glg",
}, {
    "descr": "gayo",
    "codice": "gay",
}, {
    "descr": "gbaya",
    "codice": "gba",
}, {
    "descr": "ge'ez",
    "codice": "gez",
}, {
    "descr": "georgiano",
    "codice": "geo",
}, {
    "descr": "germanica (altra lingua)",
    "codice": "gem",
}, {
    "descr": "giapponese",
    "codice": "jpn",
}, {
    "descr": "giavanese",
    "codice": "jav",
}, {
    "descr": "gilbertese",
    "codice": "gil",
}, {
    "descr": "giudeo spagnolo",
    "codice": "lad",
}, {
    "descr": "giudeo-arabo",
    "codice": "jrb",
}, {
    "descr": "giudeo-persiano",
    "codice": "jpr",
}, {
    "descr": "gondi",
    "codice": "gon",
}, {
    "descr": "gorontalo",
    "codice": "gor",
}, {
    "descr": "gotico",
    "codice": "got",
}, {
    "descr": "grebo",
    "codice": "grb",
}, {
    "descr": "greco antico (fino al 1453)",
    "codice": "grc",
}, {
    "descr": "greco moderno (dopo il 1453)",
    "codice": "gre",
}, {
    "descr": "groenlandese",
    "codice": "kal",
}, {
    "descr": "guaranì",
    "codice": "grn",
}, {
    "descr": "gujarati",
    "codice": "guj",
}, {
    "descr": "gwich'in",
    "codice": "gwi",
}, {
    "descr": "haida",
    "codice": "hai",
}, {
    "descr": "haussa",
    "codice": "hau",
}, {
    "descr": "hawaiano",
    "codice": "haw",
}, {
    "descr": "herero",
    "codice": "her",
}, {
    "descr": "hiligayna",
    "codice": "hil",
}, {
    "descr": "himachali",
    "codice": "him",
}, {
    "descr": "hindi",
    "codice": "hin",
}, {
    "descr": "hiri motu",
    "codice": "hmo",
}, {
    "descr": "hupa",
    "codice": "hup",
}, {
    "descr": "iban",
    "codice": "iba",
}, {
    "descr": "ibo",
    "codice": "ibo",
}, {
    "descr": "ijo",
    "codice": "ijo",
}, {
    "descr": "ilocano",
    "codice": "ilo",
}, {
    "descr": "indiana (altra lingua)",
    "codice": "inc",
}, {
    "descr": "indiana del Sudamerica (altra lingua)",
    "codice": "sai",
}, {
    "descr": "indiana dell'America centrale (altra lingua)",
    "codice": "cai",
}, {
    "descr": "indiana dell'America del Nord (altra lingua)",
    "codice": "nai",
}, {
    "descr": "indoeuropea (altra lingua)",
    "codice": "ine",
}, {
    "descr": "indonesiano",
    "codice": "ind",
}, {
    "descr": "inglese",
    "codice": "eng",
}, {
    "descr": "inglese medio (1100-1500)",
    "codice": "enm",
}, {
    "descr": "interlingua (lingua della Internatinal Auxiliary Language Association)",
    "codice": "ina",
}, {
    "descr": "interlingue",
    "codice": "ile",
}, {
    "descr": "inuktitut",
    "codice": "iku",
}, {
    "descr": "inupiaq",
    "codice": "ipk",
}, {
    "descr": "iranico (altra lingua)",
    "codice": "ira",
}, {
    "descr": "irlandese",
    "codice": "gle",
}, {
    "descr": "irlandese antico (fino al 900)",
    "codice": "sga",
}, {
    "descr": "irlandese medio (900-1200)",
    "codice": "mga",
}, {
    "descr": "islandese",
    "codice": "ice",
}, {
    "descr": "italiano",
    "codice": "ita",
}, {
    "descr": "ittito",
    "codice": "hit",
}, {
    "descr": "kachin",
    "codice": "kac",
}, {
    "descr": "kamba",
    "codice": "kam",
}, {
    "descr": "kanuri",
    "codice": "kau",
}, {
    "descr": "karakalpak",
    "codice": "kaa",
}, {
    "descr": "karèn",
    "codice": "kar",
}, {
    "descr": "kasmiri",
    "codice": "kas",
}, {
    "descr": "kawi",
    "codice": "kaw",
}, {
    "descr": "khasi",
    "codice": "kha",
}, {
    "descr": "khmer",
    "codice": "khm",
}, {
    "descr": "khoisan (altra lingua)",
    "codice": "khi",
}, {
    "descr": "khotanese",
    "codice": "kho",
}, {
    "descr": "kikuyu",
    "codice": "kik",
}, {
    "descr": "kimbundu",
    "codice": "kmb",
}, {
    "descr": "komi",
    "codice": "kom",
}, {
    "descr": "konkani",
    "codice": "kok",
}, {
    "descr": "kpelle",
    "codice": "kpe",
}, {
    "descr": "kru",
    "codice": "kro",
}, {
    "descr": "kuanyama",
    "codice": "kua",
}, {
    "descr": "kumyk",
    "codice": "kum",
}, {
    "descr": "kurukh",
    "codice": "kru",
}, {
    "descr": "kutenai",
    "codice": "kut",
}, {
    "descr": "lahnda",
    "codice": "lah",
}, {
    "descr": "lamba",
    "codice": "lam",
}, {
    "descr": "laotiano",
    "codice": "lao",
}, {
    "descr": "latino",
    "codice": "lat",
}, {
    "descr": "lesgo",
    "codice": "lez",
}, {
    "descr": "lettone",
    "codice": "lav",
}, {
    "descr": "lingala",
    "codice": "lin",
}, {
    "descr": "lingua imprecisata",
    "codice": "und",
}, {
    "descr": "lingue algonchine",
    "codice": "alg",
}, {
    "descr": "lingue athabaska",
    "codice": "ath",
}, {
    "descr": "lingue bamileke",
    "codice": "bai",
}, {
    "descr": "lingue berbere (altre)",
    "codice": "ber",
}, {
    "descr": "lingue diverse",
    "codice": "mis",
}, {
    "descr": "lingue irochesi",
    "codice": "iro",
}, {
    "descr": "lingue maya",
    "codice": "myn",
}, {
    "descr": "lingue otomi",
    "codice": "oto",
}, {
    "descr": "lingue salish",
    "codice": "sal",
}, {
    "descr": "lingue sami",
    "codice": "smi",
}, {
    "descr": "lingue sioux",
    "codice": "sio",
}, {
    "descr": "lingue sorabe",
    "codice": "wen",
}, {
    "descr": "lingue wakashan",
    "codice": "wak",
}, {
    "descr": "lituano",
    "codice": "lit",
}, {
    "descr": "lolo",
    "codice": "lol",
}, {
    "descr": "lozi",
    "codice": "loz",
}, {
    "descr": "luba-katanga",
    "codice": "lub",
}, {
    "descr": "luba-lulua",
    "codice": "lua",
}, {
    "descr": "luganda",
    "codice": "lug",
}, {
    "descr": "luiseno",
    "codice": "lui",
}, {
    "descr": "lunda",
    "codice": "lun",
}, {
    "descr": "luo (Kenya e Tanzania)",
    "codice": "luo",
}, {
    "descr": "lushei",
    "codice": "lus",
}, {
    "descr": "lussemburghese",
    "codice": "ltz",
}, {
    "descr": "macassar",
    "codice": "mak",
}, {
    "descr": "macedone",
    "codice": "mac",
}, {
    "descr": "madurese",
    "codice": "mad",
}, {
    "descr": "magahi",
    "codice": "mag",
}, {
    "descr": "maithili",
    "codice": "mai",
}, {
    "descr": "malayalam",
    "codice": "mal",
}, {
    "descr": "malese",
    "codice": "may",
}, {
    "descr": "malgascio",
    "codice": "mlg",
}, {
    "descr": "maltese",
    "codice": "mlt",
}, {
    "descr": "mandar",
    "codice": "mdr",
}, {
    "descr": "mandingo",
    "codice": "man",
}, {
    "descr": "manipuri",
    "codice": "mni",
}, {
    "descr": "mannese",
    "codice": "glv",
}, {
    "descr": "manobo",
    "codice": "mno",
}, {
    "descr": "maori",
    "codice": "mao",
}, {
    "descr": "maratto",
    "codice": "mar",
}, {
    "descr": "mari",
    "codice": "chm",
}, {
    "descr": "marshall",
    "codice": "mah",
}, {
    "descr": "marwari",
    "codice": "mwr",
}, {
    "descr": "masai",
    "codice": "mas",
}, {
    "descr": "mbundu",
    "codice": "umb",
}, {
    "descr": "menangkabu",
    "codice": "min",
}, {
    "descr": "mende",
    "codice": "men",
}, {
    "descr": "micmac",
    "codice": "mic",
}, {
    "descr": "mohawk",
    "codice": "moh",
}, {
    "descr": "mon-khmer (altra lingua)",
    "codice": "mkh",
}, {
    "descr": "mongolo",
    "codice": "mon",
}, {
    "descr": "mossi",
    "codice": "mos",
}, {
    "descr": "multilingue",
    "codice": "mul",
}, {
    "descr": "munda (altra lingua)",
    "codice": "mun",
}, {
    "descr": "muskogee",
    "codice": "mus",
}, {
    "descr": "nahuatl",
    "codice": "nah",
}, {
    "descr": "nauruano",
    "codice": "nau",
}, {
    "descr": "navaho",
    "codice": "nav",
}, {
    "descr": "ndebele del nord",
    "codice": "nde",
}, {
    "descr": "ndebele del sud",
    "codice": "nbl",
}, {
    "descr": "ndonga",
    "codice": "ndo",
}, {
    "descr": "nepalese",
    "codice": "nep",
}, {
    "descr": "newari",
    "codice": "new",
}, {
    "descr": "niassese",
    "codice": "nia",
}, {
    "descr": "nigero-cordofaniana (altra lingua)",
    "codice": "nic",
}, {
    "descr": "niue",
    "codice": "niu",
}, {
    "descr": "nordico antico",
    "codice": "non",
}, {
    "descr": "norvegese",
    "codice": "nor",
}, {
    "descr": "nubiano",
    "codice": "nub",
}, {
    "descr": "nyamwesi",
    "codice": "nym",
}, {
    "descr": "nyanja",
    "codice": "nya",
}, {
    "descr": "nyankole",
    "codice": "nyn",
}, {
    "descr": "nyoro",
    "codice": "nyo",
}, {
    "descr": "nzima",
    "codice": "nzi",
}, {
    "descr": "occitanico (dopo il 1500)",
    "codice": "oci",
}, {
    "descr": "ojibwa",
    "codice": "oji",
}, {
    "descr": "olandese",
    "codice": "dut",
}, {
    "descr": "olandese medio (1050-1350 ca.)",
    "codice": "dum",
}, {
    "descr": "oriya",
    "codice": "ori",
}, {
    "descr": "oromo",
    "codice": "orm",
}, {
    "descr": "osage",
    "codice": "osa",
}, {
    "descr": "osseto",
    "codice": "oss",
}, {
    "descr": "pahlavi",
    "codice": "pal",
}, {
    "descr": "palau",
    "codice": "pau",
}, {
    "descr": "pali",
    "codice": "pli",
}, {
    "descr": "pampanga",
    "codice": "pam",
}, {
    "descr": "pangasinan",
    "codice": "pag",
}, {
    "descr": "panjabi",
    "codice": "pan",
}, {
    "descr": "papiamento",
    "codice": "pap",
}, {
    "descr": "papuano, (altra lingua)",
    "codice": "paa",
}, {
    "descr": "pashto",
    "codice": "pus",
}, {
    "descr": "persiano",
    "codice": "per",
}, {
    "descr": "persiano antico (600-400 a.c. ca.)",
    "codice": "peo",
}, {
    "descr": "polacco",
    "codice": "pol",
}, {
    "descr": "ponape",
    "codice": "pon",
}, {
    "descr": "portoghese",
    "codice": "por",
}, {
    "descr": "pracrito",
    "codice": "pra",
}, {
    "descr": "provenzale antico (fino al 1500)",
    "codice": "pro",
}, {
    "descr": "quechua",
    "codice": "que",
}, {
    "descr": "rajasthani",
    "codice": "raj",
}, {
    "descr": "rapanui",
    "codice": "rap",
}, {
    "descr": "rarotonga",
    "codice": "rar",
}, {
    "descr": "rbraico",
    "codice": "heb",
}, {
    "descr": "retoromanzo",
    "codice": "roh",
}, {
    "descr": "romanza (altra lingua)",
    "codice": "roa",
}, {
    "descr": "ruanda",
    "codice": "kin",
}, {
    "descr": "rumeno",
    "codice": "rum",
}, {
    "descr": "rundi",
    "codice": "run",
}, {
    "descr": "russo",
    "codice": "rus",
}, {
    "descr": "sai",
    "codice": "vai",
}, {
    "descr": "samaritano",
    "codice": "sam",
}, {
    "descr": "samoano",
    "codice": "smo",
}, {
    "descr": "sandawe",
    "codice": "sad",
}, {
    "descr": "sango",
    "codice": "sag",
}, {
    "descr": "sanscrito",
    "codice": "san",
}, {
    "descr": "santali",
    "codice": "sat",
}, {
    "descr": "sardo",
    "codice": "srd",
}, {
    "descr": "sasak",
    "codice": "sas",
}, {
    "descr": "schiavo",
    "codice": "den",
}, {
    "descr": "scozzese",
    "codice": "sco",
}, {
    "descr": "selkup",
    "codice": "sel",
}, {
    "descr": "semitica (altra lingua)",
    "codice": "sem",
}, {
    "descr": "serer",
    "codice": "srr",
}, {
    "descr": "shan",
    "codice": "shn",
}, {
    "descr": "shona",
    "codice": "sna",
}, {
    "descr": "sidama",
    "codice": "sid",
}, {
    "descr": "siksika",
    "codice": "bla",
}, {
    "descr": "sindhi",
    "codice": "snd",
}, {
    "descr": "singalese",
    "codice": "sin",
}, {
    "descr": "sino-tibetana (altra lingua)",
    "codice": "sit",
}, {
    "descr": "siriaco",
    "codice": "syr",
}, {
    "descr": "slava (altra lingua)",
    "codice": "sla",
}, {
    "descr": "slavo ecclesiastico",
    "codice": "chu",
}, {
    "descr": "slovacco",
    "codice": "slo",
}, {
    "descr": "sloveno",
    "codice": "slv",
}, {
    "descr": "somalo",
    "codice": "som",
}, {
    "descr": "songhai",
    "codice": "son",
}, {
    "descr": "soninke",
    "codice": "snk",
}, {
    "descr": "sotho del nord",
    "codice": "nso",
}, {
    "descr": "sotho del sud",
    "codice": "sot",
}, {
    "descr": "spagnolo",
    "codice": "spa",
}, {
    "descr": "sudanese",
    "codice": "sun",
}, {
    "descr": "sukuma",
    "codice": "suk",
}, {
    "descr": "sumero",
    "codice": "sux",
}, {
    "descr": "susu",
    "codice": "sus",
}, {
    "descr": "svedese",
    "codice": "swe",
}, {
    "descr": "swahili",
    "codice": "swa",
}, {
    "descr": "swati",
    "codice": "ssw",
}, {
    "descr": "tagalog",
    "codice": "tgl",
}, {
    "descr": "tagico",
    "codice": "tgk",
}, {
    "descr": "tahitiano",
    "codice": "tah",
}, {
    "descr": "tai (altra lingua)",
    "codice": "tai",
}, {
    "descr": "tamashek",
    "codice": "tmh",
}, {
    "descr": "tamil",
    "codice": "tam",
}, {
    "descr": "tataro",
    "codice": "tat",
}, {
    "descr": "tedesco",
    "codice": "ger",
}, {
    "descr": "tedesco alto antico (750-1050 ca.)",
    "codice": "goh",
}, {
    "descr": "tedesco medio alto (1050-1500 ca.)",
    "codice": "gmh",
}, {
    "descr": "temne",
    "codice": "tem",
}, {
    "descr": "tereno",
    "codice": "ter",
}, {
    "descr": "tetum",
    "codice": "tet",
}, {
    "descr": "thai",
    "codice": "tha",
}, {
    "descr": "tibetano",
    "codice": "tib",
}, {
    "descr": "tigrino",
    "codice": "tir",
}, {
    "descr": "tigré",
    "codice": "tig",
}, {
    "descr": "tiv",
    "codice": "tiv",
}, {
    "descr": "tlingit",
    "codice": "tli",
}, {
    "descr": "tok pisin",
    "codice": "tpi",
}, {
    "descr": "tokelau",
    "codice": "tkl",
}, {
    "descr": "tonga (Isole Tonga)",
    "codice": "ton",
}, {
    "descr": "tonga (nyasa)",
    "codice": "tog",
}, {
    "descr": "tsimshian",
    "codice": "tsi",
}, {
    "descr": "tsonga",
    "codice": "tso",
}, {
    "descr": "tswana",
    "codice": "tsn",
}, {
    "descr": "tumbuka",
    "codice": "tum",
}, {
    "descr": "turco",
    "codice": "tur",
}, {
    "descr": "turco ottomano (1500-1928)",
    "codice": "ota",
}, {
    "descr": "turcomanno",
    "codice": "tuk",
}, {
    "descr": "tuvalu",
    "codice": "tvl",
}, {
    "descr": "tuvinian",
    "codice": "tyv",
}, {
    "descr": "ucraino",
    "codice": "ukr",
}, {
    "descr": "ugaritico",
    "codice": "uga",
}, {
    "descr": "ugrofinnica (altra lingua)",
    "codice": "fiu",
}, {
    "descr": "uigurico",
    "codice": "uig",
}, {
    "descr": "ungherese",
    "codice": "hun",
}, {
    "descr": "urdu",
    "codice": "urd",
}, {
    "descr": "usbeco",
    "codice": "uzb",
}, {
    "descr": "venda",
    "codice": "ven",
}, {
    "descr": "vietnamita",
    "codice": "vie",
}, {
    "descr": "volapük",
    "codice": "vol",
}, {
    "descr": "voto",
    "codice": "vot",
}, {
    "descr": "walamo",
    "codice": "wal",
}, {
    "descr": "waray",
    "codice": "war",
}, {
    "descr": "washo",
    "codice": "was",
}, {
    "descr": "wolof",
    "codice": "wol",
}, {
    "descr": "xosa",
    "codice": "xho",
}, {
    "descr": "yakut",
    "codice": "sah",
}, {
    "descr": "yao",
    "codice": "yao",
}, {
    "descr": "yap",
    "codice": "yap",
}, {
    "descr": "yiddish",
    "codice": "yid",
}, {
    "descr": "yoruba",
    "codice": "yor",
}, {
    "descr": "yupik",
    "codice": "ypk",
}, {
    "descr": "zande",
    "codice": "znd",
}, {
    "descr": "zapoteco",
    "codice": "zap",
}, {
    "descr": "zenaga",
    "codice": "zen",
}, {
    "descr": "zhuang",
    "codice": "zha",
}, {
    "descr": "zingaresco",
    "codice": "rom",
}, {
    "descr": "zulu",
    "codice": "zul",
}, {
    "descr": "zuni",
    "codice": "zun",
}];
function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
}
export const getLanguageLabelByCode = (code) => Languages.reduce((res,item) => item.codice === code ? capitalizeFirstLetter(item.descr) : capitalizeFirstLetter(res),code);