import React from "react";
import { useParams } from "react-router-dom";
import {Typography} from "@material-ui/core";
// import XxIco from '@material-ui/icons/SentimentDissatisfiedTwoTone';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

const ZeroResults = () => {
    const { t } = useTranslation();
    let {query,facets} = useParams();
    query = !!query ? decodeURIComponent(query) : undefined;
    return <>
        <Card>
            <Box mb={3}>
                <Typography variant="h3" component="h1" gutterBottom color="textSecondary">
                    {/* <XxIco style={{fontSize: "5em"}}/> */}
                    <div style={{fontSize: "5em",whiteSpace:"nowrap"}}>(-_-)</div>
                </Typography>
            </Box>
            <CardContent>
                <Typography variant="h2" component="h2" gutterBottom>
                    {t("No results found")}
                </Typography>
                {(!!query || !!facets) && <Typography variant="h4" component="h2">
                    {t("Suggestions")}
                </Typography>}
                {(!!query || !!facets) && <Typography component="div">
                    <ul>
                        {!!query && <li>{t("Check for typing errors in search terms")}: <strong>{query}</strong>;</li>}
                        {!!query && (query.replace(/^\s*|\s*$/g,'').replace(/\s+/g,' ').split(" ").length > 1) && <li>{t("Reduce the number of search terms")} ({t("the search is performed on all the inserted terms")}): <strong>{query}</strong>{facets ? ";" : "."}</li>}
                        {facets && <li>{t("Remove the applied filters")}.</li>}
                    </ul>
                </Typography>}
            </CardContent>
        </Card>
    </>
};

export default ZeroResults;
