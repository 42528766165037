import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import {isDev} from './IsDev';

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
    // we init with resources
    resources: {
        en: {
            translations: {
                "Home": "Home",
                "Stats": "Stats",
                "About": "About CNR ExploRA",
                "Contents and instruments": "Contents and instruments", 
                "Terms of use": "Terms of use",
                "Instructions": "Instructions",
                "Contacts": "Contacts",
                "Resources": "Resources",
                "CLOSE MENU": "CLOSE MENU",
                "Insert search terms ...": "Insert search terms ...",
                "home__p1": "**CNR ExploRA** (CNR Explore Research Archive) is a portal that collects and exposes the CNR scientific and technical production in order to facilitate research, navigation and access.",
                "home__p2": "The portal displays the products of \"**People**\" (Articles, Proceedings, Patents, Technical Reports, etc.).",
                "home__p3": "\"People\" is the official archive of CNR research production and is populated through self-archiving of research products by CNR staff.",
                "intro__p0": "**CNR ExploRA** (CNR Explore Research Archive) is a portal that collects and exposes the CNR scientific and technical production in order to facilitate research, navigation and access.",
                "intro__p1": "The portal displays the products of \"**People**\" (Articles, Proceedings, Patents, Technical Reports, etc.).  \n\"People\" is the official archive of CNR research production and is populated through self-archiving of research products by CNR staff.",
                "intro__p2": "The current version of this portal is prototypal, as subject to further implementations — both technical and editorial — aimed at improving services, content and how they are presented.",
                "intro__subt1": "Exhibition of research products",
                "intro__p3": "This prototypal version of CNR ExploRA exposes all products contained in People approved by the Directors of the CNR Research Institutes, making only metadata and abstracts visible, where available.  \nDigital versions of the products are not yet exposed, but they are attached to the product records in \"People\".",
                "intro__p4": "The responsibility for the accuracy and quality of the data and information relating to the products presented on this portal is attributable only to CNR staff who have deposited the products in \"People\".",
                "intro__subt2": "Future goals and developments",
                "intro__p5": "The future goal of CNR ExploRA is to expose the CNR Open Access research products, conforming to the Open Science principles of making publicly funded research products openly available, promoting their free dissemination online and ensuring their widest possible diffusion.",
                "Guidelines": "Guidelines",
                "guidelines__p1": "Allor quando il CNR avrà definito linee di indirizzo strategico (*policy istituzionale*) e altri documenti contenenti le misure di carattere organizzativo-gestionale per l’attuazione dei principi sull’accesso aperto ai prodotti della ricerca, da questa voce di menu saranno resi accessibili una serie di documenti particolarmente utili per la comunità CNR e per tutti coloro che fossero interessati a conoscere le misure adottate dall’Ente per il governo e la gestione  dell’**Archivio istituzionale dei prodotti della ricerca del CNR.**",
                "guidelines__p2": "Saranno inoltre fornite informazioni relative al contesto normativo di riferimento (incluse soft law) dell’Accesso aperto e della Scienza aperta, di livello sia nazionale che europeo.",
                "guidelines__p3": "Saranno infine rese disponibili semplici istruzioni operative specificatamente rivolte al personale CNR che, in qualità di autore, deposita i propri prodotti in People, ciò al fine di rendere più efficacemente attuabile quanto previsto dalla policy istituzionale e dagli altri documenti di profilo attuativo che saranno emanati dall’Ente.",
                "Developers": "Developers",
                "developers__p1": "I metadati dei Prodotti della Ricerca e i dati dei profili degli autori inseriti in People sono interrogabili ed esportabili in vari formati e modalità",
                "API REST": "API REST",
                "apirest__p1": "Per l'interrogazione del sistema e l'esportazione dei dati,è disponibile una semplice API REST, che è possibile esplorare e sperimentare tramite l'interfaccia Swagger di seguito riportata",
                "OAI-PMH": "Open Archives Initiative Protocol for Metadata Harvesting (OAI-PMH)",
                "oaipmh__p1": "È possibile estrarre i metadati dei prodotti della ricerca in formato XML tramite il [protocollo OAI-PMH](http://www.openarchives.org/OAI/openarchivesprotocol.html).  \nIl servizio dissemina i dati dei prodotti della ricerca presenti in People in [vari formati XML](http://www.cnr.it/oai-pmh/oai2.php?verb=ListMetadataFormats) tra cui DC, MODS e RDF",
                "oaipmh__p2": "Per utilizzare il servizio OAI-PMH è sufficiente fare riferimento al [protocollo OAI-PMH](http://www.openarchives.org/OAI/openarchivesprotocol.html) con la seguente base URL: http://www.cnr.it/oai-pmh/oai2.php",
                "oaipmh__p3": "Il set relativo ai dati di un singolo Istituto è *CDSxyz* dove *xyz* indica il codice CDS dell'Istituto (ad esempio il set relativo all'Isituto INSEAN è CDS118)",
                "oaipmh__p4": "[OAI-PMH](http://www.openarchives.org/OAI/openarchivesprotocol.html) è un protocollo per disseminazione/raccolta intere collezioni di metadati e non è adatto all'interrogazione in tempo reale mediante apposite query (fare riferimento alla [api rest](#api-rest) per queste necessità); il modo migliore di utilizzare il protocollo è quello di memorizzare localmente i dati in database locale e utilizzare tale database per l'esecuzione delle query necessarie.",
                "oaipmh__p5": "Ai fini di migliorare le prestazioni e limitare il carico di lavoro sia per i sistemi People e Gestione Prodotti sia per i che utilizzino il servizio OAP è consigliabile realizzare, dopo un primo arvesting completo, un [selective harvesting](http://www.openarchives.org/OAI/openarchivesprotocol.html#Datestamp) basato sulla data di ultima sincronizzazione al fine di ottenere solo i metadati degli aggiornamenti avvenuti dopo la data di ultima sincronizzazione.",
                "Last publications archived": "Last publications archived",
                "All publications": "All publications",
                "Licensed under CC BY 4.0": "Licensed under CC BY 4.0",
                "Made with": "Made with",
                "Institutes by publications number": "Institutes by publications' number",
                "EU projects by publications number": "EU projects by publications' number",
                "Authors by publications number": "Authors by publications' number",
                "Publications": "Research products",
                "Authors": "Authors",
                "Author": "Author",
                "Institute": "Institute",
                "Year": "Year",
                "Type": "Type",
                "Keyword": "Keyword",
                "Research program": "Research program",
                "Language": "Language",
                "Languages": "Languages",
                "EU Project": "EU Project",
                "EU Projects": "EU Projects",
                "EU Funding Program": "EU Funding Program",
                "Number of products by": "Number of products by",
                "Products by": "Products by",
                "results_from_to_of": "RESULTS FROM {{from}} TO {{to}} OF {{of}}",
                "Error": "Error",
                "Loading error": "Loading error",
                "Error 404": "Error 404",
                "We couldn't find that page": "We couldn't find that page",
                "Creation": "Creation",
                "Last update": "Last update",
                "CNR authors": "CNR authors",
                "CNR institutes": "CNR institutes",
                "External links": "External links",
                "External IDs": "External IDs",
                "Search error": "Search error",
                "An error occurred while performing the search": "An error occurred while performing the search",
                "The system may be too busy or there may be network problems": "The system may be too busy or there may be network problems",
                "If the error is caused by problems within the system, we are probably already working on it":"If the error is caused by problems within the system, we are probably already working on it",
                "Data read error": "Data read error",
                "An error occurred while reading record data": "An error occurred while reading record data",
                "No results found": "No results found",
                "Suggestions": "Suggestions",
                "Check for typing errors in search terms": "Check for typing errors in search terms",
                "Reduce the number of search terms": "Reduce the number of search terms",
                "the search is performed on all the inserted terms": "the search is performed over all the inserted terms",
                "Remove the applied filters": "Remove the applied filters",
                "No values ​​available": "No values ​​available",
                "Selected": "Selected",
                "Filter": "Filter",
                "View": "View",
                "Areas of interest": "Areas of interest",
                "E-mail": "E-mail",
                "PEC": "PEC",
                "Skype": "Skype",
                "Phone": "Phone",
                "FAX": "FAX",
                "Address": "Address",
                "International IDs": "International IDs",
                "Experience": "Experience",
                "work__at": "at",
                "Activities": "Activities",
                "Role": "Role",
                "Web site": "Web site",
                "Education": "Education",
                "Graduation date": "Graduation date",
                "Grade": "Grade",
                "Production": "Production",
                "Understanding": "Understanding",
                "Notes": "Notes",
                "Language certifications": "Language certifications",
                "Skills": "Skills",
                "Order by": "Order by",
                "Full name": "Full name",
                "Count": "Count",
                "Research activities classification ATECO": "Research activities classification ATECO",
                "Research activities classification ERC": "Research activities classification ERC",
                "today": "today",
                "N/A": "N/A",
                "Thesis": "Thesis",
                "image": "image",
                "cover__descr__00": "Publications related to COronaVIrus Disease 19 (COVID-19), an infectious respiratory disease caused by the virus called SARS-CoV-2 belonging to the coronavirus family",
                "cover__caption__00": "\"COVID-19\". Public Health Image Library (PHIL), Center for Desease Control and Prevention",
                "cover__caption__01": "Giovanni Fragalà, Samuele Barone, \"Grave complex OS 17-19OS_17-19\". The Benedictine Library Fund of the San Nicolò l'Arena Monastery – Catania. 3D Resource, from Institute for Archaeological and Monumental Heritage, CNR, 2014",
                "cover__caption__02": "Angelo Domesi, \"Who's watching who?\" Italian expedition to Antarctica.  Photo from \"Riscattiamo la scienza\", CNR",
                "cover__caption__03": "\"Philaenus spumarius: the vector insect of the olive tree killer bacterium\". A tutorial on Xylella.  From \"CNR Outreach\" web portal",
                "cover__caption__04": "Simone Battiston, \"Floating Cubes\". Institute for Energy and Interfaces – Padova. Photo from \"Riscattiamo la scienza\", CNR, 2011",
                "cover__caption__05": "Marco Grasso, \"Colour Science 2\". Institute for Physical Chemistry Processes – Messina. Photo from \"Riscattiamo la scienza\", CNR, 2013",
                "cover__caption__06": "\"Digital Infrastructure\". Image from the web portal \"DOMUS – Infrastructure for Digital Objects\". ICT Office, CNR",
                "Attached documents": "Attached documents",
            }
        },
        it: {
            translations: {
                "Home": "Home",
                "Stats": "Statistiche",
                "About": "Scopri CNR ExploRA",
                "Contents and instruments": "Contenuti e strumenti",
                "Terms of use": "Condizioni di utilizzo",
                "Instructions": "Istruzioni per autori CNR",
                "Contacts": "Contatti",
                "Resources": "Risorse",
                "CLOSE MENU": "CHIUDI MENU",
                "Insert search terms ...": "Inserisci i termini di ricerca ...",
                "home__p1": "**CNR ExploRA** (CNR Explore Research Archive) è un portale che raccoglie e rende visibile la produzione scientifica e tecnica del CNR allo scopo di facilitarne la ricerca, la navigazione e l’accesso.",
                "home__p2": "Il portale espone i prodotti (articoli, contributi in atti di convegno, brevetti, rapporti tecnici, ecc.) presenti in **People**, l’archivio ufficiale dei prodotti della ricerca del CNR, che viene alimentato mediante autoarchiviazione da parte del personale dell’Ente.",
                "home__p3": "",
                "intro__p0": "**CNR ExploRA** (CNR Explore Research Archive) è un portale che raccoglie e rende visibile la produzione scientifica e tecnica del CNR allo scopo di facilitarne la ricerca, la navigazione e l’accesso.",
                "intro__p1": "Il portale espone i prodotti (articoli, contributi in atti di convegno, brevetti, rapporti tecnici, ecc.) presenti in **People**, l’archivio ufficiale dei prodotti della ricerca del CNR, che viene alimentato mediante autoarchiviazione da parte del personale dell’Ente.",
                "intro__p2": "L’attuale versione di CNR ExploRA è da considerarsi di livello prototipale, in quanto soggetta a ulteriori implementazioni — di profilo sia tecnico sia redazionale — mirate a migliorare i servizi, i contenuti e le loro modalità di presentazione.",
                "intro__subt1": "Esposizione dei prodotti della ricerca",
                "intro__p3": "Questa versione prototipale di CNR ExploRA espone tutti i prodotti contenuti in People \"riconosciuti\" dai Direttori degli Istituti di ricerca del CNR, rendendo visibili unicamente i metadati e gli abstract, ove disponibili.  \nNon sono ancora esposte le versioni digitali dei prodotti, allegate invece ai singoli record degli stessi prodotti contenuti in People.",
                "intro__p4": "La responsabilità in merito alla correttezza e alla qualità dei dati e delle informazioni relative ai prodotti esposti in questo portale è da intendersi unicamente in capo ai soggetti che ne hanno effettuato il deposito in People.",
                "intro__subt2": "Obiettivi e sviluppi futuri",
                "intro__p5": "CNR ExploRA è stato concepito e realizzato con l’obiettivo di privilegiare in futuro l’esposizione completa delle versioni digitali dei prodotti della ricerca CNR depositate ad accesso aperto (Open Access) in People, in linea con i principi dell’apertura della scienza di rendere pubblicamente disponibili i prodotti finanziati con fondi pubblici, promuovere la loro libera disseminazione in rete e assicurarne la più ampia diffusione possibile.",
                "Guidelines": "Linee guida",
                "guidelines__p1": "Allor quando il CNR avrà definito linee di indirizzo strategico (*policy istituzionale*) e altri documenti contenenti le misure di carattere organizzativo-gestionale per l’attuazione dei principi sull’accesso aperto ai prodotti della ricerca, da questa voce di menu saranno resi accessibili una serie di documenti particolarmente utili per la comunità CNR e per tutti coloro che fossero interessati a conoscere le misure adottate dall’Ente per il governo e la gestione  dell’**Archivio istituzionale dei prodotti della ricerca del CNR.**",
                "guidelines__p2": "Saranno inoltre fornite informazioni relative al contesto normativo di riferimento (incluse soft law) dell’Accesso aperto e della Scienza aperta, di livello sia nazionale che europeo.",
                "guidelines__p3": "Saranno infine rese disponibili semplici istruzioni operative specificatamente rivolte al personale CNR che, in qualità di autore, deposita i propri prodotti in People, ciò al fine di rendere più efficacemente attuabile quanto previsto dalla policy istituzionale e dagli altri documenti di profilo attuativo che saranno emanati dall’Ente.",
                "Developers": "Sviluppatori",
                "developers__p1": "I metadati dei Prodotti della Ricerca e i dati dei profili degli autori inseriti in People sono interrogabili ed esportabili in vari formati e modalità",
                "API REST": "API REST",
                "apirest__p1": "Per l'interrogazione del sistema e l'esportazione dei dati,è disponibile una semplice API REST, che è possibile esplorare e sperimentare tramite l'interfaccia Swagger di seguito riportata",
                "OAI-PMH": "Open Archives Initiative Protocol for Metadata Harvesting (OAI-PMH)",
                "oaipmh__p1": "È possibile estrarre i metadati dei prodotti della ricerca in formato XML tramite il [protocollo OAI-PMH](http://www.openarchives.org/OAI/openarchivesprotocol.html).  \nIl servizio dissemina i dati dei prodotti della ricerca presenti in People in [vari formati XML](http://www.cnr.it/oai-pmh/oai2.php?verb=ListMetadataFormats) tra cui DC, MODS e RDF",
                "oaipmh__p2": "Per utilizzare il servizio OAI-PMH è sufficiente fare riferimento al [protocollo OAI-PMH](http://www.openarchives.org/OAI/openarchivesprotocol.html) con la seguente base URL: http://www.cnr.it/oai-pmh/oai2.php",
                "oaipmh__p3": "Il set relativo ai dati di un singolo Istituto è *CDSxyz* dove *xyz* indica il codice CDS dell'Istituto (ad esempio il set relativo all'Isituto INSEAN è CDS118)",
                "oaipmh__p4": "[OAI-PMH](http://www.openarchives.org/OAI/openarchivesprotocol.html) è un protocollo per disseminazione/raccolta intere collezioni di metadati e non è adatto all'interrogazione in tempo reale mediante apposite query (fare riferimento alla [api rest](#api-rest) per queste necessità); il modo migliore di utilizzare il protocollo è quello di memorizzare localmente i dati in database locale e utilizzare tale database per l'esecuzione delle query necessarie.",
                "oaipmh__p5": "Ai fini di migliorare le prestazioni e limitare il carico di lavoro sia per i sistemi People e Gestione Prodotti sia per i che utilizzino il servizio OAP è consigliabile realizzare, dopo un primo arvesting completo, un [selective harvesting](http://www.openarchives.org/OAI/openarchivesprotocol.html#Datestamp) basato sulla data di ultima sincronizzazione al fine di ottenere solo i metadati degli aggiornamenti avvenuti dopo la data di ultima sincronizzazione.",
                "Last publications archived": "Ultimi prodotti archiviati", 
                "All publications": "Tutti i prodotti",
                "Licensed under CC BY 4.0": "Licenza CC BY 4.0",
                "Made with": "Realizzato con",
                "Institutes by publications number": "Istituti per numero di prodotti",
                "EU projects by publications number": "Progetti Europei per numero di prodotti",
                "Authors by publications number": "Autori per numero di prodotti",
                "Publications": "Prodotti",
                "Authors": "Autori",
                "Author": "Autore",
                "Institute": "Istituto",
                "Year": "Anno",
                "Type": "Tipo",
                "Keyword": "Keyword",
                "Research program": "Attiità di ricerca",
                "Language": "Lingua",
                "Languages": "Lingue",
                "EU Project": "Progetto Europeo",
                "EU Projects": "Progetti Europei",
                "EU Funding Program": "Programma di finanziamento Europeo",
                "Number of products by": "Numero di prodotti per",
                "Products by": "Prodotti per",
                "results_from_to_of": "RISULTATI DA {{from}} A {{to}} DI {{of}}",
                "Error": "Errore",
                "Loading error": "Errore caricamento dati",
                "Error 404": "Errore 404",
                "We couldn't find that page": "Non riusciamo a trovare questa pagina",
                "Creation": "Creazione",
                "Last update": "Ultima modifica",
                "CNR authors": "Autori CNR",
                "CNR institutes": "Istituti CNR",
                "External links": "Link esterni",
                "External IDs": "ID esterni",
                "Search error": "Errore nella ricerca",
                "An error occurred while performing the search": "È avvenuto un errore durante l'esecuzione della ricerca",
                "The system may be too busy or there may be network problems": "Il sistema protrebbe essere sovraccarico o potrebbero esserci probemi di rete",
                "If the error is caused by problems within the system, we are probably already working on it": "Se il problema fosse causato da problemi interni al sistema, allora, probabilmente, ci stiamo già lavorando",
                "Data read error": "Errore nella lettura dei dati",
                "An error occurred while reading record data": "È avvenuto un errore durante la lettura dei dati del record",
                "No results found": "La ricerca non ha restituito alcun risultato",
                "Suggestions": "Suggerimenti",
                "Check for typing errors in search terms": "Verifica eventuali errori di battitura nei termini di ricerca",
                "Reduce the number of search terms": "Riduci il numero di termini di ricerca",
                "the search is performed on all the inserted terms": "la ricerca è eseguita su tutti i termini inseriti",
                "Remove the applied filters": "Rimuovi i filtri applicati",
                "No values ​​available": "Nessun valore disponibile",
                "Selected": "Selezionati",
                "Filter": "Filtro",
                "View": "Vedi prodotto",
                "Areas of interest": "Aree di interesse",
                "E-mail": "E-mail",
                "PEC": "PEC",
                "Skype": "Skype",
                "Phone": "Telefono",
                "FAX": "FAX",
                "Address": "Recapito",
                "International IDs": "Identificatori Internazionali",
                "Experience": "Esperienze lavorative",
                "work__at": "presso",
                "Activities": "Attività",
                "Role": "Ruolo",
                "Web site": "Sito web",
                "Education": "Formazione",
                "Graduation date": "Data conseguimento",
                "Grade": "Votazione",
                "Production": "Produzione",
                "Understanding": "Comprensione",
                "Notes": "Note",
                "Language certifications": "Certificazioni",
                "Skills": "Competenze",
                "Order by": "Ordina per",
                "Full name": "Nome",
                "Count": "Conteggio",
                "Research activities classification ATECO": "Classificazione attività di ricerca ATECO",
                "Research activities classification ERC": "Classificazione attività di ricerca ERC",
                "today": "oggi",
                "N/A": "N/D",
                "Thesis": "Tesi",
                "image": "immagine",
                "cover__descr__00": "Pubblicazioni relative al COronaVIrus Disease 19 (COVID-19), malattia infettiva respiratoria causata dal virus denominato SARS-CoV-2 appartenente alla famiglia dei coronavirus",
                "cover__caption__00": "\"COVID-19\". Public Health Image Library (PHIL), Center for Desease Control and Prevention",
                "cover__caption__01": "Giovanni Fragalà, Samuele Barone, \"Complesso di tombe OS 17-19OS_17-19\", Fondo benedettino del Monastero di San Nicolò l'Arena – Catania\".  Risorsa 3D dell’Istituto per i Beni Archeologici e Monumentali, CNR, 2014",
                "cover__caption__02": "Angelo Domesi \"Chi guarda chi?\", Spedizione italiana in Antartide. Foto da \"Riscattiamo la scienza\", CNR",
                "cover__caption__03": "Philaenus spumarius: l’insetto vettore del batterio killer degli ulivi\", un tutorial sulla Xylella. Dal portale web \"CNR Outreach",
                "cover__caption__04": "Simone Battiston, \"Cubi fluttuanti\", Istituto per l'Energetica e le Interfasi – Padova. Foto da \"Riscattiamo la scienza\", CNR, 2011",
                "cover__caption__05": "Marco Grasso, \"La scienza a colori 2\", Istituto per i Processi Chimico Fisici – Messina. Foto da \"Riscattiamo la scienza\", CNR, 2013",
                "cover__caption__06": "\"Digital Infrastructure\". Immagine dal portale web \"DOMUS – Infrastructure for Digital Objects\", Ufficio ICT, CNR ",
                "Attached documents": "Documenti allegati",
            }
        },
    },    
    fallbackLng: "en",
    debug: isDev,
    // returnObjects: true,
    
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    
    keySeparator: false, // we use content as keys
    
    interpolation: {
        escapeValue: false
    }
});

export default i18n;