import React from "react";
import { useTranslation } from "react-i18next";
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search';
import Hidden from '@material-ui/core/Hidden';
import InputBase from '@material-ui/core/InputBase';
import { useHistory, useParams, Link } from 'react-router-dom';
import logo from '../logo.svg';
import {getSearchUrl} from "../SearchService";
import HamburgerMenu from './HamburgerMenu';
import SetLang from "./SetLang";

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    form: {
        flex: "1 1 auto",
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        display: 'flex',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        width: '100%',
    },
    linkLogo: {
        display:"flex",
        color:theme.palette.primary.contrastText,
        textDecoration:"none",
        alignItems:"center",
        margin: theme.spacing(0, 2, 0, 0),
        "& img": {
            marginRight: theme.spacing(1),
            height: "32px"
        }
    },
    g1: {
        flex: 1,
        [theme.breakpoints.up('sm')]: {
            display: "flex",
            alignItems: "center",
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(2),
        },
    },
    g0: {
        display:"flex",
        alignItems:"center"
    },
    toolbar: {
        [theme.breakpoints.down('md')]: {
            display: "block",
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    tbbutton: {
        whiteSpace: "nowrap",
    },
}));

export const menuItemsConfig = [
    // {title: "About", href: "/about"},
    // {title: "Instructions", href: "/instructions"},
    {title: "Publications", href: "/search"},
    {title: "Authors", href: "/authors"},
    {title: "Stats", href: "/stats"},
    // {title: "Contacts", href: "/contacts"},
];

export const MenuItems = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
    <React.Fragment>
        {menuItemsConfig.map(({title, href}, key) => <Button key={key} className={classes.tbbutton} color="inherit" component="a" href={href}>{t(title)}</Button> )}
        <SetLang />
    </React.Fragment>
    );
}

export const SearchForm = () => {
    const { t } = useTranslation();
    const {kind, query} = useParams();
    const history = useHistory();
    const classes = useStyles();
    let searchInput = React.createRef();
    const doSearch = (ev) => {
        ev.preventDefault();
        history.push(getSearchUrl({kind,query: searchInput.value}));
    };
    return (
        <form className={classes.form} onSubmit={doSearch}>
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder={t("Insert search terms ...")}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    name='query'
                    defaultValue={decodeURIComponent(query||'')}
                    autoComplete='off'
                    inputRef={el => searchInput = el}
                />
            </div>
        </form>
    );
}

const ApplicationBar = () => {
    const classes = useStyles();
    return <AppBar position="static" elevation={0}>
        <Toolbar className={classes.toolbar}>
            <div className={classes.g1}>
                <div className={classes.g0}>
                    <Hidden smUp><HamburgerMenu /></Hidden>
                    <Link to="/" className={classes.linkLogo}>
                        {process.env.REACT_APP_SHOW_LOGO_IN_APPLICATIONBAR === "true" ? <img alt={process.env.REACT_APP_SHOW_TITLE_IN_APPLICATIONBAR === true ? "" : process.env.REACT_APP_TITLE} src={logo}/> : null}
                        {process.env.REACT_APP_SHOW_TITLE_IN_APPLICATIONBAR === "true" ? <Typography style={{whiteSpace:"nowrap"}} variant="h6" className={classes.title}>{process.env.REACT_APP_TITLE}</Typography> : null}
                    </Link>
                </div>
                <SearchForm />
            </div>
            <Hidden xsDown><MenuItems /></Hidden>
        </Toolbar>
    </AppBar>
};

export default ApplicationBar;
