import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#63a4ff',
            main: '#1976d2',
            dark: '#004ba0',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ffa040',
            main: '#ff6f00',
            dark: '#c43e00',
            contrastText: '#fff',
        },
    },
});

ReactDOM.render(<ThemeProvider theme={theme}>
    <I18nextProvider i18n={i18n}>
        <App />
    </I18nextProvider>
</ThemeProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
