import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@material-ui/core/Box';
import {
    makeStyles,
    Typography,
    AppBar,
    Button,
    IconButton,
    Toolbar,
    Paper,
    Link,
    Hidden,
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SearchForm, MenuItems } from '../components/ApplicationBar';
import logo from '../logo.svg';
import Fade from '@material-ui/core/Fade';
import Markdown from 'react-markdown';
import HamburgerMenu from './HamburgerMenu';
const useStyles = makeStyles(theme => ({
    cover: {
        position: "relative",
        minHeight: "100vh",
        display: "flex",
        flexFlow: "column",
	    background: "linear-gradient(#1976d2, transparent), linear-gradient(to top left, #000099, transparent), linear-gradient(to top right, #003, transparent)",
        backgroundBlendMode: "screen",
        color:theme.palette.primary.contrastText,
        "& form": {
            margin: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                margin: `${theme.spacing(4)}px 30vw 0 10vw`,
                flex: 0, 
            },
        },
        "& .infobox": {
            margin: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                margin: `${theme.spacing(4)}px 30vw 0 10vw`,
            },
        },
        "& .appname": {
            margin: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.up('md')]: {
                margin: "10vh 10vw 0 10vw",
            },
            "& h1": {
                fontSize: theme.typography.h3.fontSize,
                fontWeight: 300,
                [theme.breakpoints.up('md')]: {
                    fontSize: theme.typography.h2.fontSize,
                    fontWeight: theme.typography.h2.fontWeight,
                },
            },
            "& img": {
                marginRight: theme.spacing(2),
                height: 50,
                [theme.breakpoints.up('md')]: {
                    height: 70,
                },
            },
        },
    },
    appBar: {
        background: "rgba(0,0,0,0.6)",
        position: "relative",
        zIndex: 1,
        [theme.breakpoints.up('md')]: {
            "& > *": {
                justifyContent: "flex-end",
            }
        }
    },
    item: {
        position: "absolute",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        zIndex:0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    details: {
        background: "rgba(0,0,0,0.5)",
        color: "#fff",
        margin: theme.spacing(2),
        marginBottom: 0,
        position: "relative",
        zIndex: 1,
        [theme.breakpoints.up('md')]: {
            margin: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px auto`,
            width: 500,
            maxWidth: "calc(50vw - 64px)",
        },
    },
    c: {
        background: "rgba(0,0,0,0.5)",
        color: "#fff",
        padding: theme.spacing(1),
        position: "relative",
        zIndex: 1,
        margin: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            margin: 0,
            position: "absolute",
            bottom: 0,
            left: 0,
            maxWidth: "calc(50vw - 64px)",
        },
    },
    logoAndSearch: {
        background: "linear-gradient(rgba(0,0,0,0.6) 50%, transparent)",
        position: "relative",
        zIndex: 1,
        paddingBottom: "10vh",
    },
    callToScroll: {
        zIndex: 1,
        position: "relative",
        fontSize:theme.typography.h1.fontSize,
        opacity: 0.7,
        display: "block",
        margin: "0 auto",
        "&:hover,&:focus,&:active": {
            opacity: 1,
            cursor: "pointer",
        },
        [theme.breakpoints.up('md')]: {
            position: "absolute",
            margin: 0,
            bottom: 0,
            left: "calc(50vw - 48px)",
            right: 0,
        }
    },
    linkLogo: {
        display:"flex",
        color:theme.palette.primary.contrastText,
        textDecoration:"none",
        alignItems:"center",
        margin: theme.spacing(0, 2, 0, 0),
        "& img": {
            marginRight: theme.spacing(1),
            height: "32px"
        }
    },
    selectLng: {
        "& *": {
            color: "#fff",
        }
    },
}));

const items = [
    {
        id:"covid",
        title: "COVID-19",
        description: "cover__descr__00",
        link: "/search/q/COVID-19",
        caption: "cover__caption__00",
    },{
        id:"heritage",
        title: "3D visualization cultural heritage",
        // description: "Pubblicazioni relative alla visualizzazione 3D di risorse culturali",
        link: "/search/q/3D visualization cultural heritage",
        caption: "cover__caption__01",
    },{
        id:"antartide",
        title: "Antartic research",
        // description: "",
        link: "/search/q/antartic research",
        caption: "cover__caption__02",
    },{
        id: "xylella",
        title: "Xylella",
        // description: "",
        link: "/search/q/xylella",
        caption: "cover__caption__03",
    },{
        id: "cubi",
        title: "Mineral Crystal-Structure",
        // description: "",
        link: "/search/q/Mineral Crystal-Structure",
        caption: "cover__caption__04",
    },{
        id: "scienzacolori",
        title: "Ion laser",
        // description: "",
        link: "/search/q/Ion laser",
        caption: "cover__caption__05",
    },{
        id: "digital-infrastructure",
        title: "Digital infrastructure",
        // description: "",
        link: "/search/q/Digital infrastructure",
        caption: "cover__caption__06",
    }
]

const gotoRecentPubs = (ev) => {
    ev.preventDefault();
    document.querySelector("#recentPubs").scrollIntoView({behavior: 'smooth'});
}

const TopBar = () => {
    const classes = useStyles();
    return (
        <AppBar elevation={0} className={classes.appBar}>
            <Toolbar>
                <Hidden smUp>
                    <div style={{display:"flex",alignItems:"center"}}>
                        <HamburgerMenu />
                        <Link to="/" className={classes.linkLogo}>
                            {process.env.REACT_APP_SHOW_LOGO_IN_APPLICATIONBAR === "true" ? <img alt={""} src={logo}/> : null}
                            {process.env.REACT_APP_SHOW_TITLE_IN_APPLICATIONBAR === "true" ? <Typography style={{whiteSpace:"nowrap"}} variant="h6" className={classes.title}>{process.env.REACT_APP_TITLE}</Typography> : null}
                        </Link>
                    </div>
                </Hidden>
                <Hidden xsDown>
                    <MenuItems />
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

const LogoAndSearch = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    return (
        <div className={classes.logoAndSearch}>
            <Hidden xsDown><Box className="appname">
                {process.env.REACT_APP_SHOW_LOGO_IN_HOMECOVER === "true" ? <img alt={process.env.REACT_APP_SHOW_TITLE_IN_HOMECOVER === true ? "" : process.env.REACT_APP_TITLE} src={logo}/> : null}
                {process.env.REACT_APP_SHOW_TITLE_IN_HOMECOVER === "true" ? <Typography style={{whiteSpace:"nowrap"}} component="h1" className={classes.title}>{process.env.REACT_APP_TITLE}</Typography> : null}
            </Box></Hidden>
            <SearchForm />
            <Box className="infobox">
                <Typography variant="caption" component="div"><Markdown renderers={{paragraph:Box}}>{t("home__p1")}</Markdown></Typography>
                <Typography variant="caption" component="div"><Markdown renderers={{paragraph:Box}}>{t("home__p2")}</Markdown></Typography>
                <Typography variant="caption" component="div"><Markdown renderers={{paragraph:Box}}>{t("home__p3")}</Markdown></Typography>
            </Box>
        </div>
    );
}

const CoverItem = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [activeIndex,setActiveIndex] = useState(Math.floor(0 * Math.random() * (items.length)));
    const goForward = () => setActiveIndex((activeIndex + 1 === items.length) ? 0 : activeIndex + 1);
    const goBack = () => setActiveIndex((activeIndex === 0) ? (items.length - 1) : activeIndex - 1);
    const item = items[activeIndex];
    const {title, description, caption, link} = item;
    return (<>
        {items.map(({id},key) => {
            const bgr = `url("/images/${id}.jpeg"), url("/images/${id}.xs.jpeg")`;
            return  (
                <Fade key={key} in={activeIndex===key}>
                    <div className={classes.item} style={{backgroundImage:bgr}}></div>
                </Fade>
            );
        })}
        <Fade in={true}>
            <Paper className={classes.details}>
                <Box p={2}>
                    <Typography variant="h4" gutterBottom><Link href={link} color="inherit">{title}</Link></Typography>
                    {description && <Typography><Link href={link} color="inherit">{t(description)}</Link></Typography>}
                </Box>
                <Toolbar>
                    <IconButton color="inherit" onClick={goBack}>
                        <ArrowBackIcon/>
                    </IconButton>
                    <IconButton color="inherit" onClick={goForward}>
                        <ArrowForwardIcon/>
                    </IconButton>
                    <span style={{flex:1}}></span>
                    <Button color="inherit" component="a" href={link}>Scopri</Button>
                </Toolbar>
            </Paper>
        </Fade>
        <Fade in={true}>
            <Typography variant="caption" component="div" className={classes.c}>{t("image")}: {t(caption)}</Typography>
        </Fade>
    </>);

}

const HomeCover = () => {
    const classes = useStyles();
    return <Box className={classes.cover}>
        <TopBar />
        <LogoAndSearch />
        <div style={{flex:1}}></div>
        <CoverItem />
        <ExpandMoreIcon onClick={gotoRecentPubs} className={classes.callToScroll}/>
    </Box>;    
}

export default HomeCover;