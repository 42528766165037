import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, Typography, Tooltip } from "@material-ui/core";
import useAxios, { configure } from 'axios-hooks'
import { getFacetLabel } from '../components/FacetsPanels';
import { getLanguageLabelByCode } from '../Languages';
import { reduceFacetCounts, getSolrQueryUrlForFacetStats, getSearchUrl, escapeForSolr, getSolrQueryUrlForConteggi } from '../SearchService';
import { Base64 } from 'js-base64';
import { EuStarsIcon, CnrLogoIcon } from '../assets/ExtraIcons';
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import { useHistory } from 'react-router-dom';
import textSize from 'svg-text-size'
import CountUp from 'react-countup';
import Skeleton from '@material-ui/lab/Skeleton';
import PrintError from '../components/PrintError';
import { Title, Body } from './StaticPages';
const useStyles = makeStyles(theme => ({
    box: {
        padding: theme.spacing(2, 2, 2, 2),
        border: "1px solid rgba(0,0,0,0.2)",
        borderRadius: theme.spacing(0.5),
        background: theme.palette.background.default,
        // flex: 1,
    },
}));

const ListTemplate = (props) => {
    const classes = useStyles();
    return <Grid item xs={12} md={4} style={{ display: "flex" }}><div style={{ flex: 1 }} className={classes.box}>{props.children}</div></Grid>;
}

const ListBlockError = ({ error }) => (
    <ListTemplate>
        <Typography variant="h5">{useTranslation().t("Loading error")}</Typography>
        <Grid container spacing={0}>
            <PrintError {...{ error }} />
        </Grid>
    </ListTemplate>
);

const ListBlockLoader = () => (
    <ListTemplate>
        <Typography variant="h5"><Skeleton type="text" width="70%" /></Typography>
        <Grid container spacing={0}>
            {Array.apply(null, Array(20)).map((item, key) => (
                <Grid item xs={12} sm={6} md={12} lg={6} key={key}>
                    <ListItem>
                        <ListItemAvatar>
                            <Skeleton animation="wave" variant="circle" width={40} height={40} />
                        </ListItemAvatar>
                        <ListItemText primary={<Skeleton type="text" width="80%" />} secondary={<Skeleton type="text" width="50%" />} />
                    </ListItem>
                </Grid>
            ))}
        </Grid>
    </ListTemplate>
);

const AutoriBlock = () => {
    const { t } = useTranslation();
    const solrQueryUrl = getSolrQueryUrlForFacetStats("autoricnr_s-i-s-m", 20, 1);
    configure({ cache: false })
    const [{ data, loading, error }] = useAxios(solrQueryUrl);
    if (error) return <ListBlockError {...{ error }} />;
    if (loading || !data) return <ListBlockLoader />;
    const { facet_counts } = data || {};
    const counts = reduceFacetCounts(facet_counts)[0].counts.map(count => {
        const label = count.label.replace(/\$:(.+):\$$/, "");
        const login = count.label.replace(/^.+\$:(.+):\$$/, "$1");
        const avatarUrl = `${process.env.REACT_APP_AUTHORPROFILEIMAGE_ENDPOINT}${login}`;
        const facetValue = escapeForSolr(count.label);
        const facet = Base64.encodeURI(`autoricnr_s-i-s-m:"${facetValue}"`);
        const searchUrl = getSearchUrl({ facets: [facet] });
        const iniziali = label.split(" ").map(str => str.substr(0, 1).toUpperCase()).join("").slice(0,3);
        return { ...count, ...{ label, searchUrl, avatarUrl, iniziali } };
    });
    return (
        <ListTemplate>
            <Typography variant="h5">{t("Authors by publications number")} (top {counts.length})</Typography>
            <List>
                {counts.map(({ label, searchUrl, avatarUrl, iniziali, count }, key) => (
                    <ListItem key={key} button component="a" href={searchUrl}>
                        <ListItemAvatar>
                            <Avatar alt={label} src={avatarUrl}><Avatar>{iniziali}</Avatar></Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={label} secondary={`${count} prodotti`} />
                    </ListItem>
                ))}
            </List>
        </ListTemplate>
    );
}

const IstitutiBlock = () => {
    const { t } = useTranslation();
    const solrQueryUrl = getSolrQueryUrlForFacetStats("strutturecnr_s-i-s-m", 20, 1);
    configure({ cache: false })
    const [{ data, loading, error }] = useAxios(solrQueryUrl);
    if (error) return <ListBlockError {...{ error }} />;
    if (loading || !data) return <ListBlockLoader />;
    const { facet_counts } = data || {};
    const counts = reduceFacetCounts(facet_counts)[0].counts.map(count => {
        const sigla = count.label.split(',')[0];
        const facetValue = escapeForSolr(count.label);
        const facet = Base64.encodeURI(`strutturecnr_s-i-s-m:"${facetValue}"`);
        const searchUrl = getSearchUrl({ facets: [facet] });
        return { ...count, ...{ sigla, searchUrl } };
    });
    return (<ListTemplate>
        <Typography variant="h5">{t("Institutes by publications number")} (top {counts.length})</Typography>
        <List>
            {counts.map(({ searchUrl, sigla, label, count }, key) => (
                <Tooltip title={label}>
                    <ListItem key={key} button component="a" href={searchUrl}>
                        <ListItemAvatar>
                            <Avatar>
                                <CnrLogoIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={sigla} secondary={`${count} prodotti`} />
                    </ListItem>
                </Tooltip>
            ))}
        </List>
    </ListTemplate>);
}

const ProgettiEuropeiBlock = () => {
    const { t } = useTranslation();
    const solrQueryUrl = getSolrQueryUrlForFacetStats("progettieuropei_acronym_s-i-s-m", 20, 1);
    configure({ cache: false })
    const [{ data, loading, error }] = useAxios(solrQueryUrl);
    if (error) return <ListBlockError {...{ error }} />;
    if (loading || !data) return <ListBlockLoader />;
    const { facet_counts } = data || {};
    const counts = reduceFacetCounts(facet_counts)[0].counts.map(count => {
        const facetValue = escapeForSolr(count.label);
        const facet = Base64.encodeURI(`progettieuropei_acronym_s-i-s-m:"${facetValue}"`);
        const searchUrl = getSearchUrl({ facets: [facet] });
        return { ...count, ...{ searchUrl } };
    });
    return (<ListTemplate>
        <Typography variant="h5">{t("EU projects by publications number")} (top {counts.length})</Typography>
        <List>
            {counts.map(({ label, count, searchUrl }, key) => (
                <ListItem key={key} button component="a" href={searchUrl}>
                    <ListItemAvatar>
                        <Avatar style={{ background: "#003399" }}>
                            <EuStarsIcon style={{ color: "#FFCC00" }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={label} secondary={`${count} prodotti`} />
                </ListItem>
            ))}
        </List>
    </ListTemplate>);
}

const ChartBlock = ({ facet, limit, mincount, type, label }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    limit = limit || 20;
    type = type || "bar";
    const solrQueryUrl = getSolrQueryUrlForFacetStats(facet, limit, mincount, type === 'pie');
    configure({ cache: false })
    const [{ data, loading, error }] = useAxios(solrQueryUrl);
    if (error) return (
        <Grid item xs={12}>
            <div className={classes.box}>
                <Typography variant="h5">{t("Loading error")}</Typography>
                <Box style={{ height: 455 }}>
                    <PrintError {...{ error }} />
                </Box>
            </div>
        </Grid>
    );
    if (loading || !data) return (
        <Grid item xs={12}>
            <div className={classes.box}>
                <Typography variant="h5"><Skeleton width="60%" /></Typography>
                <Skeleton variant="rect" height={468} width='100%' />
            </div>
        </Grid>
    );
    const { facet_counts, response } = data || {};
    const { numFound } = response || {};
    const title = getFacetLabel(reduceFacetCounts(facet_counts)[0].label);
    let counts = reduceFacetCounts(facet_counts)[0].counts;
    const chBar = ({ id, value, index, indexValue, color, data }) => {
        const facetValue = escapeForSolr(data.label);
        const searchUrl = getSearchUrl({ facets: [Base64.encodeURI(`${facet}:"${facetValue}"`)] });
        history.push(searchUrl);
    };
    const chPie = ({ id }) => {
        const facetValue = escapeForSolr(id.split(" ")[0]);
        const searchUrl = getSearchUrl({ facets: [Base64.encodeURI(`${facet}:"${facetValue}"`)] });
        history.push(searchUrl);
    };
    if (facet === "anno_s-i-s") {
        counts = counts.sort((a, b) => (a.label < b.label) ? 1 : -1).slice(0, limit);
    }
    if (facet === "lingua_s-i-s-m") {
        counts = counts.map(({ count, label }) => ({ count, abbr: label, label: getLanguageLabelByCode(label) }))
    }
    const height = 500 / 20 * counts.length;
    if (type === "pie") {
        counts = counts.map(({ count, label, abbr }) => ({ id: `${abbr || label} ${(count / numFound * 100).toFixed(2)}%`, value: count, label: `${label} (${(count / numFound * 100).toFixed(2)}%)` }))
    }
    const labelLength = counts.reduce((maxLength, { label }) => (textSize(label + "---", { 'letter-spacing': '1px' }).width > maxLength) ? textSize(label + "---", { 'letter-spacing': '1px' }).width : maxLength, 0);
    const Chart = (type === "bar") ? (
        <Box style={{ height: height, width: '100%' }}>
            <Typography variant="h5">{t("Number of products by")} {t(title)} (top {counts.length})</Typography>
            <ResponsiveBar
                onClick={chBar}
                height={height}
                colors={{ scheme: 'paired' }}
                layout="horizontal"
                data={counts.reverse()}
                keys={['count']}
                indexBy="label"
                margin={{ top: 0, right: 0, bottom: 48, left: labelLength * 11 / 16 }}
                labelSkipWidth={30}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                tooltip={({ data, value, color }) => (<span>{data.label}: <strong>{value}</strong>  prodotti</span>)}
                theme={{
                    tooltip: {
                        container: {
                            background: '#333',
                            color: '#FFF'
                        },
                    },
                }}
                onMouseEnter={(_data, event) => {
                    event.target.style.cursor = 'pointer';
                }}
            />
        </Box>
    ) : (<Box style={{ height: 500 }}>
        <Typography variant="h5">% {t("Products by")} {t(title)}</Typography>
        <ResponsivePie
            animate={true}
            slicesLabelsSkipAngle={15}
            radialLabelsSkipAngle={5}
            data={counts}
            onClick={chPie}
            height={500}
            margin={{ top: 48, right: 90, bottom: 48, left: 48 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={{ scheme: 'paired' }}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: 'color' }}
            slicesLabelsTextColor="#333333"
            motionStiffness={90}
            motionDamping={15}
            theme={{
                tooltip: {
                    container: {
                        background: '#333',
                        color: '#FFF'
                    },
                },
            }}
            onMouseEnter={(_data, event) => {
                event.target.style.cursor = 'pointer';
            }}
            legends={[
                {
                    anchor: 'top-right',
                    direction: 'column',
                    translateX: -20,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemTextColor: '#999',
                    symbolSize: 16,
                    symbolShape: 'circle',
                }
            ]}
        />
    </Box>);
    return (
        <Grid item xs={12}>
            <div className={classes.box}>
                {Chart}
            </div>
        </Grid>
    );

}

const BoxContatoriTemplate = (props) => {
    const classes = useStyles();
    return <Grid {...props} item xs={12} md={3}>
        <div style={{ flex: 1 }} className={classes.box}>
            <Typography variant="h2" component="p" style={{ textAlign: "center" }}>{props.children}</Typography>
            <Typography variant="h5" component="p" style={{ textAlign: "center" }}>{props.title}</Typography>
        </div>
    </Grid>;
}

const BoxContatori = () => {
    const { t } = useTranslation();
    const solrQueryUrl = getSolrQueryUrlForConteggi();
    configure({ cache: false });
    const [{ data, loading, error }] = useAxios(solrQueryUrl);
    if (error) return <>
        <BoxContatoriTemplate title="Publications">{t("Error")}!</BoxContatoriTemplate>
        <BoxContatoriTemplate title="Authors">{t("Error")}!</BoxContatoriTemplate>
        <BoxContatoriTemplate title="Languages">{t("Error")}!</BoxContatoriTemplate>
        <BoxContatoriTemplate title="EU Projects">{t("Error")}!</BoxContatoriTemplate>
    </>
    if (loading || !data) return <>
        <BoxContatoriTemplate title={<Skeleton style={{ margin: "auto" }} variant="text" width="40%" />}><Skeleton style={{ margin: "auto" }} variant="text" width="70%" /></BoxContatoriTemplate>
        <BoxContatoriTemplate title={<Skeleton style={{ margin: "auto" }} variant="text" width="40%" />}><Skeleton style={{ margin: "auto" }} variant="text" width="70%" /></BoxContatoriTemplate>
        <BoxContatoriTemplate title={<Skeleton style={{ margin: "auto" }} variant="text" width="40%" />}><Skeleton style={{ margin: "auto" }} variant="text" width="70%" /></BoxContatoriTemplate>
        <BoxContatoriTemplate title={<Skeleton style={{ margin: "auto" }} variant="text" width="40%" />}><Skeleton style={{ margin: "auto" }} variant="text" width="70%" /></BoxContatoriTemplate>
    </>
    const { facets } = data;
    const { count } = facets;
    const countLingua = facets["lingua_s-i-s-m"];
    const countAutori = facets["autoricnr_s-i-s-m"];
    const countProgettiEuropei = facets["progettieuropei_acronym_s-i-s-m"];
    return <>
        <BoxContatoriTemplate title={t("Publications")}><CountUp end={count} duration={5} /></BoxContatoriTemplate>
        <BoxContatoriTemplate title={t("Authors")}><CountUp end={countAutori} duration={5} /></BoxContatoriTemplate>
        <BoxContatoriTemplate title={t("Languages")}><CountUp end={countLingua} duration={5} /></BoxContatoriTemplate>
        <BoxContatoriTemplate title={t("EU Projects")}><CountUp end={countProgettiEuropei} duration={5} /></BoxContatoriTemplate>
    </>
}

const Stats = () => (
    <Body>
        <Title>{useTranslation().t("Stats")}</Title>
        <Box pb={2}>
            <Grid container spacing={2}>
                <BoxContatori />
            </Grid>
        </Box>
        <Grid container spacing={2}>
            <ChartBlock facet="anno_s-i-s" limit={20} />
            <ChartBlock facet="tipo_s-i-s" limit={20} />
            <IstitutiBlock />
            <AutoriBlock />
            <ProgettiEuropeiBlock />
            <ChartBlock facet="lingua_s-i-s-m" mincount={100} limit={10} type="pie" />
            <ChartBlock facet="progettieuropei_fundingprogram_s-i-s-m" limit={100} type="pie" />
        </Grid>
    </Body>
);

export default Stats;