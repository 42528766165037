import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next'
import useAxios, {configure} from 'axios-hooks'
import { useParams, useHistory } from 'react-router-dom';
import {makeStyles, AppBar} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DownloadIcon from '@material-ui/icons/GetApp';
import FilterListIcon from '@material-ui/icons/FilterList';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Base64 } from 'js-base64';
import { getAssetUrl, getDocumentsListUrl, getDocumentDownloadUrl, getSearchUrl, escapeForSolr } from '../SearchService';
import to from 'to-case';
import {EuStarsIcon, PdfIcon, DocIcon, ImageIcon} from '../assets/ExtraIcons';
import {isDev} from '../IsDev';
import {DataReadError} from "./StaticPages"
import Skeleton from "@material-ui/lab/Skeleton";
import PrintJson from '../components/PrintJson';
import {capitalizeWords, humanFileSize} from '../Utils';

const estraiDati = (xml) => {
    const doc = (new DOMParser()).parseFromString(xml,"application/xml");
    const oaipmhError = doc.querySelector('OAI-PMH > error')?.firstChild?.nodeValue;
    if(oaipmhError) throw oaipmhError;
    const id = doc.querySelector('prodotto > id')?.firstChild?.nodeValue;
    const anno = doc.querySelector('prodotto > anno')?.firstChild?.nodeValue;
    const titolo = doc.querySelector('prodotto > titolo')?.firstChild?.nodeValue;
    const doi = doc.querySelector('prodotto > doi')?.firstChild?.nodeValue;
    const autori = doc.querySelector('prodotto > autori')?.firstChild?.nodeValue;
    const affiliazioni = doc.querySelector('prodotto > affiliazioni')?.firstChild?.nodeValue;
    const abstract = doc.querySelector('prodotto > abstract, prodotto > sintesi')?.firstChild?.nodeValue;
    const tipo = doc.querySelector('prodotto > relations > id_tipo [index="etichetta"]')?.firstChild?.nodeValue;
    const lingua = Array.from(doc.querySelectorAll('prodotto > lingua > item')).map(item => item.firstChild.nodeValue);
    const parole_chiave = Array.from(doc.querySelectorAll('prodotto > parole_chiave > item')).map(item => item.firstChild.nodeValue);
    const creazione = doc.querySelector('prodotto > creazione')?.firstChild?.nodeValue;
    const ultima_modifica = doc.querySelector('prodotto > ultima_modifica')?.firstChild?.nodeValue;
    const pagina_da = doc.querySelector('prodotto > pagina_da')?.firstChild?.nodeValue;
    const pagina_a = doc.querySelector('prodotto > pagina_a')?.firstChild?.nodeValue;
    const url = doc.querySelector('prodotto > url')?.firstChild?.nodeValue;
    const indicizzato_da = Array.from(doc.querySelectorAll('prodotto > indicizzato_da > item')).reduce((acc,item)=> {
        const bancadati = item.querySelector('[index="bancadati"]')?.firstChild?.nodeValue;
        const id = item.querySelector('[index="id"]')?.firstChild?.nodeValue
        if(bancadati && id){
            acc.push({bancadati,id});
        } 
        return acc
    },[]);

    const rivista__titolo = doc.querySelector('prodotto > relations > rivista2 > [index="titoli"] > [index="titolo"]')?.firstChild?.nodeValue;
    const numero_volume = doc.querySelector('prodotto > numero_volume')?.firstChild?.nodeValue;
    const numero_fascicolo = doc.querySelector('prodotto > numero_fascicolo')?.firstChild?.nodeValue;
    const congresso_nome = doc.querySelector('prodotto > congresso_nome')?.firstChild?.nodeValue;
    const congresso_luogo = doc.querySelector('prodotto > congresso_luogo')?.firstChild?.nodeValue;
    const congresso_data = doc.querySelector('prodotto > congresso_data')?.firstChild?.nodeValue;
    let source = [];
    if(congresso_nome) {
        let parts = [congresso_nome];
        if(pagina_da) parts.push(`pp. ${pagina_da || "?"}–${pagina_a || "?"}`);
        if(congresso_luogo) parts.push(congresso_luogo);
        if(congresso_data) parts.push(congresso_data);
        source.push(parts.join(", "));
    } else if(rivista__titolo) {
        let pagine = pagina_da ? `, pp. ${pagina_da}–${pagina_a || "?"}` : '';
        source.push(`${rivista__titolo} ${numero_volume||''} ${numero_fascicolo ? " (" + numero_fascicolo + ")":""}${pagine}`);
    }
    const autoricnr = Array.from(doc.querySelectorAll('metadata prodotto relations autoricnr > item')).map(au => {
        const login = au.querySelector('[index="login"]')?.firstChild?.nodeValue;
        const nome = au.querySelector('[index="nome"]')?.firstChild?.nodeValue;
        const cognome = au.querySelector('[index="cognome"]')?.firstChild?.nodeValue;
        return {nome,cognome,login}
    });
    const strutturecnr = Array.from(doc.querySelectorAll('metadata prodotto relations strutturecnr > item')).map(st => {
        const cds = st.querySelector('[index="cds"]')?.firstChild?.nodeValue;
        const denominazione = st.querySelector('[index="denominazione"]')?.firstChild?.nodeValue;
        const sigla = st.querySelector('[index="sigla"]')?.firstChild?.nodeValue;
        return {cds, denominazione, sigla};
    }).filter(struttura => struttura.cds !== '000');
    const euprojects = Array.from(doc.querySelectorAll('metadata prodotto relations progettieuropei > item')).map(st => {
        const code = st.querySelector('[index="code"]')?.firstChild?.nodeValue;
        const acronym = st.querySelector('[index="acronym"]')?.firstChild?.nodeValue;
        const title = st.querySelector('[index="title"]')?.firstChild?.nodeValue;
        const fundingprogram = st.querySelector('[index="fundingprogram"]')?.firstChild?.nodeValue;
        return {code, acronym, title, fundingprogram};
    });

    return {
        id,
        tipo,
        lingua,
        anno,
        titolo,
        doi,
        autori,
        affiliazioni,
        abstract,
        parole_chiave,
        creazione,
        ultima_modifica,
        source,
        url,
        indicizzato_da,
        autoricnr,
        strutturecnr,
        euprojects,
    }
}

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

const useStyles = makeStyles(theme => ({
    flex: {
        display: "flex",
        flexFlow: "column",
        flexGrow: 1
    },
    p: {
        padding: theme.spacing(0,2,2,2)
    },
    grid: {
        flexDirection: "row-reverse",
    },
    abstract: {
        margin: theme.spacing(2,0,2,0),
    },
    card: {
        marginBottom: theme.spacing(2),
    },
    header: {
        background: theme.palette.primary.light,
        marginBottom: "-10rem",
        height: "10rem", 
    },
    appbar: {
        background: theme.palette.primary.light,
    },
    ellipsis: {
        whiteSpace: "nowrap",
        width: "100%",
        textOverflow: "ellipsis",
        display: "inline-block",
        overflow: "hidden"
    }
}));

export const AutoreCNR = ({autore}) => {
    const {nome,cognome,login,fullname, count} = autore;
    const nominativo = capitalizeWords(fullname ? fullname : cognome + " " + nome);
    const iniziali = nominativo.split(" ").map(str => str.substr(0, 1).toUpperCase()).join("").slice(0,3);
    const avatarUrl = `${process.env.REACT_APP_AUTHORPROFILEIMAGE_ENDPOINT}${login}`;
    const facetValue = escapeForSolr(`${nominativo}$:${login}:$`);
    const facet = Base64.encodeURI(`autoricnr_s-i-s-m:"${facetValue}"`);
    const searchUrl = getSearchUrl({facets:[facet]});
    return <ListItemLink href={`/authors/${login}`} disabled={!login}>
        <ListItemAvatar>
            <Avatar alt={nominativo} src={avatarUrl}><Avatar>{iniziali}</Avatar></Avatar>
        </ListItemAvatar>
        <ListItemText primary={`${nominativo}${count !== undefined ? ` (${count})`:''}`} />
        {!!login && <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="link" component="a" href={searchUrl}>
                <FilterListIcon />
            </IconButton>
        </ListItemSecondaryAction>}
    </ListItemLink>
}
const StrutturaCNR = ({struttura}) => {
    const {cds,denominazione,sigla} = struttura;
    const facetLabel = `${sigla}${denominazione ? ", " : ""}${denominazione}`.replace(/^\s+|\s+$/g,"").replace(/\s+/g," ");
    const facetValue = escapeForSolr(facetLabel);
    const facet = Base64.encodeURI(`strutturecnr_s-i-s-m:"${facetValue}"`);
    const searchUrl = getSearchUrl({facets:[facet]});
    const avatarUrl = `https://www.cnr.it/it/istituto/${cds}/logo`;
    return <ListItemLink href={searchUrl}>
        <ListItemAvatar>
            <Avatar alt={facetLabel} src={avatarUrl}><Avatar>{sigla}</Avatar></Avatar>
        </ListItemAvatar>
        <ListItemText primary={facetLabel}/>
    </ListItemLink>
}
const EUProject = ({project}) => {
    const {code, acronym, title, fundingprogram} = project;
    const facetLabel = `${acronym}`;
    const facetValue = escapeForSolr(acronym);
    const facet = Base64.encodeURI(`progettieuropei_acronym_s-i-s-m:"${facetValue}"`);
    const searchUrl = getSearchUrl({facets:[facet]});
    return <ListItemLink href={`http://cordis.europa.eu/projects/${code}`}>
        <ListItemAvatar>
            <Avatar style={{color:"#FC0",backgroundColor:"#039"}}><EuStarsIcon /></Avatar>
        </ListItemAvatar>
        {/* <ListItemText primary={facetLabel}  secondary={<Link href={`http://cordis.europa.eu/projects/${code}`}>{`${title} [${fundingprogram}]`}</Link>} /> */}
        <ListItemText primary={facetLabel}  secondary={`${title} [${fundingprogram}]`} />
        <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="link" component="a" href={searchUrl}>
                <FilterListIcon />
            </IconButton>
        </ListItemSecondaryAction>
    </ListItemLink>;
}


export const ListItemWithAvatarLoader = () => <ListItem>
    <ListItemAvatar>
        <Skeleton variant="circle" width={40} height={40} />
    </ListItemAvatar>
    <ListItemText primary={<Skeleton width="80%" />}/>
</ListItem>;

const AssetViewMoreDataLoader = () => {
    const classes = useStyles();
    return <Box>
        <Card className={classes.card}>
                <CardContent>
                    <Typography gutterBottom><Skeleton width="50%" /></Typography>
                    <Typography gutterBottom><Skeleton width="60%" /></Typography>
                    <Typography gutterBottom><Skeleton width="50%" /></Typography>
                    <Typography gutterBottom><Skeleton width="40%" /></Typography>
                    <Typography gutterBottom><Skeleton width="50%" /></Typography>
                </CardContent>
        </Card>
        <Card className={classes.card}>
            <CardContent>
                <Typography><Skeleton width="7em" /></Typography>
                <List dense={true}>
                    <ListItemWithAvatarLoader />
                    <ListItemWithAvatarLoader />
                    <ListItemWithAvatarLoader />
                </List>
            </CardContent>
        </Card>
        <Card className={classes.card}>
            <CardContent>
                <Typography><Skeleton width="8em" /></Typography>
                <List dense={true}>
                    <ListItemWithAvatarLoader />
                    <ListItemWithAvatarLoader />
                </List>
            </CardContent>
        </Card>
        <Card className={classes.card}>
            <CardContent>
                <Typography><Skeleton width="7em" /></Typography>
                <List dense={true}>
                    <ListItemWithAvatarLoader />
                    <ListItemWithAvatarLoader />
                </List>
            </CardContent>
        </Card>
        <Card className={classes.card}>
            <CardContent>
                <Typography><Skeleton width="10em" /></Typography>
                <Typography><Skeleton width="70%" /></Typography>
                <Typography><Skeleton width="50%" /></Typography>
                <Typography><Skeleton width="60%" /></Typography>
                <Typography><Skeleton width="50%" /></Typography>
            </CardContent>
        </Card>
        <Card className={classes.card}>
            <CardContent>
                <Typography><Skeleton width="8em" /></Typography>
                <Typography><Skeleton width="70%" /></Typography>
                <Typography><Skeleton width="50%" /></Typography>
                <Typography><Skeleton width="60%" /></Typography>
            </CardContent>
        </Card>
    </Box>
}

const DocumentoAllegato = ({documento}) => {
    let Icon = DocIcon;
    const {id, etichetta, descrizione, filename, extension, mimetype, bytes} = documento;
    const downloadUrl = getDocumentDownloadUrl(id);
    if (extension === 'pdf' || mimetype === 'application/pdf') {
        Icon = PdfIcon;
    }
    if (/^image\//.test(mimetype)) {
        Icon = ImageIcon;
    }
    const secondary = <React.Fragment>
        <Typography component="span" display="block">{descrizione}</Typography>
        <Typography component="span" display="block" variant="caption">{filename} ({humanFileSize(bytes)}) </Typography>
    </React.Fragment>;
    return <ListItemLink href={downloadUrl} alignItems="flex-start">
        <ListItemAvatar>
            <Avatar>
                <Icon style={{height: "40px", width: "40px"}} />
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary={etichetta} secondary={secondary}/>
        <ListItemSecondaryAction title="Download">
            <IconButton edge="end" aria-label="link" component="a" href={downloadUrl}>
                <DownloadIcon />
            </IconButton>
        </ListItemSecondaryAction>
    </ListItemLink>;
}

const AssetViewAllegati = ({prodotto}) => {
    const { t } = useTranslation();
    const classes = useStyles(); 
    const { id } = prodotto;
    configure({cache:false});
    const [{ data, loading, error }] = useAxios(getDocumentsListUrl(id));
    return (<Box>
        {(data && data.length > 0) && <Card className={classes.card}>
            <CardContent>
                <Typography><strong>{t("Attached documents")}</strong></Typography>
                <List dense={true}>
                    {data.map((documento,key)=><DocumentoAllegato key={key} documento={documento} />)}
                </List>
            </CardContent>
        </Card>}
        {isDev && <PrintJson data={{ data, loading, error }} />}
    </Box>);
}

const AssetVidewMoreData = ({prodotto}) => {
    const { t } = useTranslation();
    const classes = useStyles(); 
    const {
        id,
        doi,
        anno,
        tipo,
        creazione,
        ultima_modifica,
        indicizzato_da,
        autoricnr,
        strutturecnr,
        euprojects,
        url,
    } = prodotto
    return (
    <Box>
        <Card className={classes.card}>
                <CardContent>
                    <Typography gutterBottom><strong>ID</strong>: {id}</Typography>
                    <Typography gutterBottom>
                        <strong>{t("Year")}</strong>: <Link href={getSearchUrl({facets:[Base64.encodeURI(`anno_s-i-s:"${anno}"`)]})}>{anno}</Link>
                    </Typography>
                    <Typography gutterBottom>
                        <strong>{t("Type")}</strong>: <Link href={getSearchUrl({facets:[Base64.encodeURI(`tipo_s-i-s:"${tipo}"`)]})}>{tipo}</Link>
                    </Typography>
                    {creazione && <Typography gutterBottom><strong>{t("Creation")}</strong>: {creazione}</Typography>}
                    <Typography gutterBottom><strong>{t("Last update")}</strong>: {ultima_modifica}</Typography>
                </CardContent>
        </Card>
        <AssetViewAllegati prodotto={prodotto} />
        {!!(autoricnr.length) && <Card className={classes.card}>
            <CardContent>
                <Typography><strong>{t("CNR authors")}</strong></Typography>
                <List dense={true}>
                    {autoricnr.map((autore,key)=><AutoreCNR key={key} autore={autore} />)}
                </List>
            </CardContent>
        </Card>}
        {!!(strutturecnr.length) && <Card className={classes.card}>
            <CardContent>
                <Typography><strong>{t("CNR institutes")}</strong></Typography>
                <List dense={true}>
                    {strutturecnr.map((struttura,key)=><StrutturaCNR key={key} struttura={struttura} />)}
                </List>
            </CardContent>
        </Card>}
        {!!(euprojects.length) && <Card className={classes.card}>
            <CardContent>
                <Typography><strong>{t("EU Projects")}</strong></Typography>
                <List dense={true}>
                    {euprojects.map((project,key)=><EUProject  key={key} project={project} />)}
                </List>
            </CardContent>
        </Card>}
        <Card className={classes.card}>
            <CardContent>
                <Typography><strong>{t("External links")}</strong></Typography>
                <Typography>OAI-PMH: <Link href={`${process.env.REACT_APP_OAIPMH_ENDPOINT}?verb=GetRecord&metadataPrefix=oai_dc&identifier=oai:it.cnr:prodotti:${id}`}>Dublin Core</Link></Typography>
                <Typography>OAI-PMH: <Link href={`${process.env.REACT_APP_OAIPMH_ENDPOINT}?verb=GetRecord&metadataPrefix=mods&identifier=oai:it.cnr:prodotti:${id}`}>Mods</Link></Typography>
                <Typography>OAI-PMH: <Link href={`${process.env.REACT_APP_OAIPMH_ENDPOINT}?verb=GetRecord&metadataPrefix=rdf&identifier=oai:it.cnr:prodotti:${id}`}>RDF</Link></Typography>
                {!!doi && <Typography>DOI: <Link href={`https://dx.doi.org/${doi.replace(/^https?:\/\/dx\.doi\.org\//,'')}`}>{doi}</Link></Typography>}
                {!!url && <Typography className={classes.ellipsis}>URL: <Link href={url}>{url}</Link></Typography>}
            </CardContent>
        </Card>
        <Card className={classes.card}>
            <CardContent>
                <Typography><strong>{t("External IDs")}</strong></Typography>
                <Typography><strong>CNR OAI-PMH</strong>: oai:it.cnr:prodotti:{id}</Typography>
                {!!doi && <Typography><strong>DOI</strong>: {doi}</Typography>}
                {indicizzato_da.map((idx,key) => <Typography key={key}><strong>{idx.bancadati}</strong>: {idx.id}</Typography>)}
            </CardContent>
        </Card>
    </Box>
    );
}

const AssetVidewDataLoaader = () => {
    return <Card>
        <CardContent>
            <Typography color="textSecondary" gutterBottom>
                <Skeleton width="50%" />
            </Typography>
            <Typography variant="h3" component="h2" gutterBottom>
                <Skeleton width="80%" />
                <Skeleton width="100%" />
                <Skeleton width="40%" />
            </Typography>
            <Typography gutterBottom>
                <Skeleton width="60%" />
            </Typography>
            <Typography color="textSecondary" gutterBottom><Skeleton width="70%" /></Typography>
            {(Array.apply(null, Array(20))).map((item,key) => <Typography key={key}><Skeleton width="100%" /></Typography>)}
            <Typography><Skeleton width="40%" /></Typography>
            <Typography color="textSecondary" gutterBottom><Skeleton width="60%" /></Typography>
            <Typography variant="h5" component="h3" gutterBottom><Skeleton width="6em" /></Typography>
            <Typography gutterBottom>
                <Skeleton width="70%" />
            </Typography>
            <Typography variant="h5" component="h3" gutterBottom><Skeleton width="7em" /></Typography>
            <Typography gutterBottom>
                <Skeleton width="80%" />
            </Typography>
            <Typography variant="h5" component="h3" gutterBottom><Skeleton width="8em" /></Typography>
            <Typography gutterBottom>
                <Skeleton width="70%" />
            </Typography>
        </CardContent>
    </Card>; 
}

const AssetVidewData = ({prodotto}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        tipo,
        lingua,
        abstract,
        anno,
        doi,
        titolo,
        autori,
        affiliazioni,
        parole_chiave,
        source,
        autoricnr,
        strutturecnr,
    } = prodotto;
    return <Card>
        <CardContent>
            <Typography color="textSecondary" gutterBottom>
                <Link href={getSearchUrl({facets:[Base64.encodeURI(`tipo_s-i-s:"${tipo}"`)]})}>{tipo}</Link><span>, </span> 
                <Link href={getSearchUrl({facets:[Base64.encodeURI(`anno_s-i-s:"${anno}"`)]})}>{anno}</Link><span>, </span>
                {lingua.map((lingua,key) => {
                    return <span key={key}>
                        {key > 0 && <span>/</span>}
                        <Link href={getSearchUrl({facets:[Base64.encodeURI(`lingua_s-i-s-m:"${lingua}"`)]})}>{lingua.toUpperCase()}</Link>
                    </span>
                })}
                {doi && `, ${doi}`}
            </Typography>
            <Typography variant="h3" component="h2" gutterBottom>
                {titolo}
            </Typography>
            <Typography gutterBottom>
                {autori}
            </Typography>
            {!!affiliazioni && <Typography color="textSecondary" gutterBottom>{affiliazioni}</Typography>}
            {/* <Typography variant="h5" component="h3" gutterBottom>Abstract</Typography> */}
            <Typography component="p" gutterBottom className={classes.abstract}>{abstract}</Typography>
            {source.map((item,key) => <Typography key={key} color="textSecondary" gutterBottom>{item}</Typography>)}
            <Typography variant="h5" component="h3" gutterBottom>Keywords</Typography>
            <Typography gutterBottom>
            {parole_chiave.map((kw,key) => <span key={key}>
                {(key > 0) && <span>, </span>}
                <Link href={getSearchUrl({facets:[Base64.encodeURI(`parole_chiave_s-i-s-m:"${escapeForSolr(kw)}"`)]})}>{kw}</Link>
            </span>)}
            </Typography>
            <Typography variant="h5" component="h3" gutterBottom>{t("CNR authors")}</Typography>
            <Typography gutterBottom>
            {autoricnr.map((autore,key) => {
                const {nome,cognome,login} = autore;
                const facetLabel = to.capital(cognome + " " + nome);
                const facetValue = escapeForSolr(`${facetLabel}$:${login}:$`);
                const facet = Base64.encodeURI(`autoricnr_s-i-s-m:"${facetValue}"`);
                const searchUrl = getSearchUrl({facets:[facet]});
                return <span key={key}>
                    {(key > 0) && <span>, </span>}
                    <Link href={searchUrl}>{facetLabel}</Link>
                </span>
            })}
            </Typography>
            <Typography variant="h5" component="h3" gutterBottom>{t("CNR institutes")}</Typography>
            <Typography gutterBottom>
            {strutturecnr.map((struttura,key) => {
                const {denominazione, sigla} = struttura;
                const facetLabel = `${sigla}${denominazione ? ", " : ""}${denominazione}`.replace(/^\s+|\s+$/g,"").replace(/\s+/g," ");
                const displayLabel = `${sigla}${denominazione ? " – " : ""}${denominazione}`.replace(/^\s+|\s+$/g,"").replace(/\s+/g," ");
                const facetValue = escapeForSolr(facetLabel);
                const facet = Base64.encodeURI(`strutturecnr_s-i-s-m:"${facetValue}"`);
                const searchUrl = getSearchUrl({facets:[facet]});
                return <span key={key}>
                    {(key > 0) && <span>, </span>}
                    <Link href={searchUrl}>{displayLabel}</Link>
                </span>
            })}
            </Typography>
        </CardContent>
    </Card> 
}

const AssetViewPage = () => {
    const classes = useStyles();
    const {id} = useParams();
    const history = useHistory();
    const goBack = () => history.go(-1);
    configure({cache:false});
    const [{ data, loading, error }] = useAxios(getAssetUrl(id));
    useEffect(()=> { window.scrollTo(0, 0) });
    if (error) return <DataReadError {...{error}} />;
    if (loading || !data) return <Box>
        <AppBar position="static" elevation={0} className={classes.appbar}>
            <Toolbar>
                <IconButton disabled color="inherit">
                    <ArrowBackIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
        <div className={classes.header}></div>
        <Box ml={2} mr={2} mb={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} style={{paddingTop:"0"}}>
                    <AssetVidewDataLoaader />
                </Grid>
                <Grid item xs={12} md={4} style={{paddingTop:"0"}}>
                    <AssetViewMoreDataLoader />
                </Grid>
            </Grid>
        </Box>
    </Box>;
    try {
        const prodotto = estraiDati(data);
        const {oaipmhError} = prodotto;
        if(oaipmhError) throw oaipmhError;
        return <Box>
            <AppBar position="static" elevation={0} className={classes.appbar}>
                <Toolbar>
                    <IconButton onClick={goBack} color="inherit">
                        <ArrowBackIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={classes.header}></div>
            <Box ml={2} mr={2} mb={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} style={{paddingTop:"0"}}>
                        <AssetVidewData prodotto={prodotto} />
                        {isDev && <PrintJson data={prodotto} />}
                    </Grid>
                    <Grid item xs={12} md={4} style={{paddingTop:"0"}}>
                        <AssetVidewMoreData prodotto={prodotto} />
                    </Grid>
                </Grid>
            </Box>
        </Box>;
    } catch (error) {
        return <DataReadError {...{error}} />;
    }
};

export default AssetViewPage;
