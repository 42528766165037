import { Base64 } from 'js-base64';
import { v4 as uuidv4 } from 'uuid';

const uuid = uuidv4();
const escapeRegEx = /(\+|-|&&|&|\|\||!|\(|\)|\{|\}|\[|\]|\^|"|~|\*|\?|:|\\|\/)/g;

export const escapeForSolr = str => str.replace(escapeRegEx,"\\$1");
export const unescapeForSolr = str => str.replace(/\\(.)/g,"$1");

const toSolrQuery = (query) => {
    query=decodeURIComponent(query||"");
    let solrQuery = "";
    if(!query) {
        return solrQuery;
    }
    if(/^(?:solr|adv):/.test(query)) {
        return query.replace(/^(?:solr|adv):(.+)/,"$1");
    }
    query = query
        .replace(/[-+:^~'"*?&!|(){}[\]]/g, ' ')
        .replace(/[:'",/\\]/g,' ')
        .replace(/ +/g,' ')
        .replace(/^\s+|\s+$/g,'')
        .replace(escapeRegEx,"\\$1");
    if(query !== "") {
        solrQuery = `TEXT:(+${query.split(" ").map(term => `/.*${term}/.*`).join(" +")})`;
    }
    return solrQuery;
};
export const getAssetUrl = (id) => {
    return `${process.env.REACT_APP_GETASSET_ENDPOINT}${id}?uuid=${uuid}`;
}

export const getDocumentsListUrl = (id) => {
    return `${process.env.REACT_APP_GETDOCUMENTSLIST_ENDPOINT}${id}?uuid=${uuid}`;
}

export const getDocumentDownloadUrl = (id) => {
    return `${process.env.REACT_APP_DOCUMENTDOWNLOAD_ENDPOINT}${id}`;
}

export const getSolrQueryUrlForConteggi = () => {
    let queryParams = new URLSearchParams();
    const facets = ["lingua_s-i-s-m","tipo_s-i-s","autoricnr_s-i-s-m","progettieuropei_acronym_s-i-s-m"];
    const jsonFacet = "{" + (facets.map(f => `${f}:"unique(${f})"`).join(",")) + "}";
    queryParams.append("wt","json");
    queryParams.append("q","*:*");
    queryParams.append("rows",0);
    queryParams.append("json.facet",jsonFacet);
    queryParams.append("uuid",uuid);
    return `${process.env.REACT_APP_SEARCH_ENDPOINT}?${queryParams.toString()}`;
}

export const getSolrQueryUrlForFacetStats = (facet,limit,mincount,notnull) => {
    notnull = notnull || false;
    let queryParams = new URLSearchParams();
    queryParams.append("wt","json");
    queryParams.append("q","*:*");
    if(notnull) {
        queryParams.append("fq",`${facet}:*`);
    }
    queryParams.append("facet.field",`{!ex=${facet}}${facet}`);
    queryParams.append("facet.mincount",mincount || 1);
    queryParams.append("facet.limit",limit || 20);
    queryParams.append("f.anno_s-i-s.facet.limit",-1);
    queryParams.append("rows",0);
    queryParams.append("facet",true);
    queryParams.append("uuid",uuid);
    return `${process.env.REACT_APP_SEARCH_ENDPOINT}?${queryParams.toString()}`;
}

export const getSolrQueryUrlForFacet = ({facet,query,facets,limit,offset,filter,sort}) => {
    query = toSolrQuery(query);
    var queryParams = new URLSearchParams();
    queryParams.append("wt","json");
    queryParams.append("rows",0);
    if(!!query) {
        queryParams.append("q",query);
    }
    sort = sort || "count"
    let F = {};
    if(!!facets){
        F = facets.split(",").reduce((acc,val) => {
            const matches = Base64.decode(val).match(/^([^:]+):(.+)$/);
            const field = matches[1];
            const value = matches[2];
            if(acc[field]) {
                acc[field].push(value);
            } else {
                acc[field] = [value];
            }
            return acc;
        },{});
        for(var f in F) {
            if(f !== facet) {
                const fq = `{!tag=${f}}${f}:(${F[f].join(" ")})`;
                queryParams.append("fq", fq);
            } 
        }
    }
    queryParams.append("facet.field",`{!ex=${facet}}${facet}`);
    if(!!filter){
        queryParams.append("facet.contains",filter);
        queryParams.append("facet.contains.ignoreCase","true");
    }
    // queryParams.append("facet.limit",limit);
    queryParams.append("facet.limit",-1);
    queryParams.append("facet.offset",offset || 0);
    queryParams.append("facet.mincount",1);
    queryParams.append("facet.sort",sort);
    queryParams.append(`stats`,"true");
    queryParams.append(`statsfacet`,"true");
    queryParams.append(`stats.field`,facet);
    queryParams.append("facet",true);
    queryParams.append("uuid",uuid);
    return `${process.env.REACT_APP_SEARCH_ENDPOINT}?${queryParams.toString()}`;
}

export const getSolrQueryUrl = (params) => {
    let {query, cds, login, rows, facets, page, sort} = params;
    query = toSolrQuery(query);
    rows = rows ? rows : process.env.REACT_APP_ROWS_DEFAULT;
    const start = ((page || 1) - 1) * rows;
    var queryParams = new URLSearchParams();
    queryParams.append("wt","json");
    if(!!query) {
        queryParams.append("q",query);
    }
    let F = {};
    if(!!facets){
        F = facets.split(",").reduce((acc,val) => {
            const matches = Base64.decode(val).match(/^([^:]+):(.+)$/);
            const field = matches[1];
            const value = matches[2];
            if(acc[field]) {
                acc[field].push(value);
            } else {
                acc[field] = [value];
            }
            return acc;
        },{});
        for(var facet in F) {
            const fq = `${facet}:(${F[facet].join(" ")})`;
            queryParams.append("fq", fq);
        }
    }
    if(!!cds) {
        queryParams.append("fq", `cds_s-i-s-m:${cds}`);
    }
    if(!!login) {
        queryParams.append("fq", `autoricnrlogin_s-i-s-m:${login}`);
    }
    queryParams.append("start",start);
    queryParams.append("rows",rows);
    if(!!sort) {
        queryParams.append("sort",sort);
    }
    queryParams.append("uuid",uuid);
    return `${process.env.REACT_APP_SEARCH_ENDPOINT}?${queryParams.toString()}`;
}

export const getSearchUrl = (params) => {
    let {query, facets, page} = params;
    query = query ? `/q/${encodeURIComponent(query)}` : '';
    facets = (facets && facets.length) ? `/f/${facets}` : '';
    page = page > 1 ? `/${page}` : '';
    return `/search${query}${facets}${page}`;
}

export const getAuthorUrl = (params) => {
    let {login, page} = params;
    page = page > 1 ? `/${page}` : '';
    return `/authors/${login}${page}`;
}

export const getInstituteUrl = (params) => {
    let {cds, query, page} = params;
    query = query ? `/q/${encodeURIComponent(query)}` : '';
    page = page > 1 ? `/${page}` : '';
    return `/institutes/${cds}${query}${page}`;
}

const reduceCounts = (acc,item,i) => {
    if(i % 2 === 0) {
        acc.push({label:item});
    } else {
        acc[acc.length-1] = {
            label: acc[acc.length-1].label,
            count: item
        };  
    }
    return acc;
}

export const reduceFacetCounts = (facet_counts) => {
    const {facet_fields} = facet_counts;
    let F = [];
    for(var facet in facet_fields) {
        if(facet!=="count") F.push({label:facet,counts:facet_fields[facet]});
    }
    const FF = F.map((f,k) => ({label:f.label, counts:f.counts.reduce(reduceCounts,[])}));
    return FF;
}