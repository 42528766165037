import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';
import {makeStyles} from "@material-ui/core";
import Homepage from "./pages/Homepage";
import SearchPage from "./pages/SearchPage";
import AssetViewPage from "./pages/AssetViewPage";
import AuthorViewPage from "./pages/AuthorViewPage";
import InstituteViewPage from "./pages/InstituteViewPage";
import AuthorsPage from "./pages/AuthorsPage";
import ApplicationBar from './components/ApplicationBar';
import Footer from './components/Footer';
import { About, Guidelines, Developers, Contacts, Error404 } from './pages/StaticPages';
import Stats from './pages/Stats';
import './App.css';

const searchPaths = [
    "/search/q/:query/k/:kind/f/:facets/:page?",
    "/search/q/:query/k/:kind/:page?",
    "/search/q/:query/f/:facets/:page?",
    "/search/q/:query/:page?",
    "/search/k/:kind/f/:facets/:page?",
    "/search/k/:kind/:page?",
    "/search/f/:facets/:page?",
    "/search/:page?"
];

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexFlow: "column",
        minHeight: "100vh",
    },
    flex: {
        display: "flex",
        flexFlow: "column",
        flexGrow: 1,
    }
}));

const App = () => {
    const classes = useStyles();
    return <Router basename={process.env.REACT_APP_ROUTER_BASENAME}>
        <div className={classes.root}>
            <Route path={[...searchPaths,"/(.+)"]} component={ApplicationBar} />
            <div className={classes.flex}>
                <Switch>
                    <Route exact path="/" component={Homepage} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/guidelines" component={Guidelines} />
                    <Route exact path="/developers" component={Developers} />
                    {/* <Route exact path="/instructions" component={Instructions} /> */}
                    <Route exact path="/stats" component={Stats} />
                    <Route exact path="/contacts" component={Contacts} />
                    <Route path={searchPaths} component={SearchPage} />
                    <Route path="/doc/:id" component={AssetViewPage} />
                    <Route path="/institutes/:cds/q/:query/:page?" component={InstituteViewPage} />
                    <Route path="/institutes/:cds/q/:query" component={InstituteViewPage} />
                    <Route path="/institutes/:cds/:page?" component={InstituteViewPage} />
                    <Route path="/institutes/:cds" component={InstituteViewPage} />
                    <Route path="/authors/:login/q/:query/:page?" component={AuthorViewPage} />
                    <Route path="/authors/:login/q/:query" component={AuthorViewPage} />
                    <Route path="/authors/:login/:page?" component={AuthorViewPage} />
                    <Route path="/authors/:login" component={AuthorViewPage} />
                    <Route path="/authors" component={AuthorsPage} />
                    <Route path="*" component={Error404} />
                </Switch>
            </div>
            <Footer />
        </div>
    </Router>
}

export default App;
