import React, {useState, useEffect} from "react";
import useAxios, {configure} from 'axios-hooks'
import {fade, makeStyles} from "@material-ui/core";
import { Base64 } from 'js-base64';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import SchoolIcon from '@material-ui/icons/School';
import PersonIcon from '@material-ui/icons/Person';
import TodayIcon from '@material-ui/icons/Today';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import LanguageIcon from '@material-ui/icons/Language';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import {EuStarsIcon} from '../assets/ExtraIcons';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import {getLanguageLabelByCode} from '../Languages';
import {getSearchUrl, escapeForSolr, unescapeForSolr, getSolrQueryUrlForFacet } from '../SearchService';
import { useTranslation } from "react-i18next";
import PrintError from './PrintError';
import {parsedContains} from '../Utils';

const useStyles = makeStyles(theme => ({
    autoComplete: {
        margin: `0 0 ${theme.spacing(3)}px 0`,
    },
    pop: {
        padding: theme.spacing(3),
        paddingTop: 0,
        paddingBottom: 0,
        width:450,
        minWidth:"100%",
        boxSizing:"border-box",
    },
    p0: {
        padding: 0,
    },
    mw: {
        maxWidth:"100%",
        overflow:"auto",
    },
    hAvatar: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    list: {
        padding: 0,
    },
    form: {
        flex: "1 1 auto",
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.black, 0.08),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.12),
        },
        marginLeft: 0,
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        display: 'flex',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        width: '100%',
    },
    pagination: {
        justifyContent: "center",
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            flexWrap: "nowrap",
            marginTop: 0,
        },
        [theme.breakpoints.up('md')]: {
            flexWrap: "wrap",
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.up('xl')]: {
            flexWrap: "nowrap",
            marginTop: 0,
        },
    },
    actions: {
        display: "block",
        [theme.breakpoints.up('sm')]: {
            display: "flex",
        },
        [theme.breakpoints.up('md')]: {
            display: "block",
        },
        [theme.breakpoints.up('xl')]: {
            display: "flex",
        },
    }
}));

export const getFacetLabel = (facet) => {
    if(facet === "strutturecnr_s-i-s-m") return "Institute";
    if(facet === "autoricnr_s-i-s-m") return "Author";
    if(facet === "anno_s-i-s") return "Year";
    if(facet === "tipo_s-i-s") return "Type";
    if(facet === "parole_chiave_s-i-s-m") return "Keyword";
    if(facet === "lingua_s-i-s-m") return "Language";
    if(facet === "attivitaricercacnr_s-i-s-m") return "Research program";
    if(facet === "progettieuropei_fundingprogram_s-i-s-m") return "EU Funding Program";
    if(facet === "progettieuropei_acronym_s-i-s-m") return "EU Project";
    return facet;  
}

const facetIcon = (facet) => {
    if(facet === "strutturecnr_s-i-s-m") return <SchoolIcon />;
    if(facet === "autoricnr_s-i-s-m") return <PersonIcon />;
    if(facet === "anno_s-i-s") return <TodayIcon />;
    if(facet === "tipo_s-i-s") return <MenuBookIcon />;
    if(facet === "attivitaricercacnr_s-i-s-m") return <AccountTreeIcon />;
    if(facet === "progettieuropei_fundingprogram_s-i-s-m") return <EuStarsIcon />;
    if(facet === "progettieuropei_acronym_s-i-s-m") return <EuStarsIcon />;
    if(facet === "parole_chiave_s-i-s-m") return <SpellcheckIcon />;
    if(facet === "lingua_s-i-s-m") return <LanguageIcon />;
    return facet.substring(0,1).toUpperCase();
}

const LoaderListItem = () => <ListItem role={undefined} dense button>
    <ListItemIcon>
        <Box style={{padding:"9px 9px 9px 0"}}>
            <Skeleton variant="rect" width={24} height={24} />
        </Box>
    </ListItemIcon>
    <ListItemText primary={<Skeleton width={`${(Math.floor(Math.random() * (10 - 6)) + 6)*10}%`} />} />
</ListItem>

const LI = ({value, checked, labelId, primary, handleToggle}) => <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
    <ListItemIcon>
        <Checkbox
            edge="start"
            checked={checked}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
        />
    </ListItemIcon>
    <ListItemText id={labelId} primary={primary} />
</ListItem>;

const SelectedFacets = ({value, primary, handleToggle}) => <Tooltip title={primary}><Chip
    style={{margin:"4px",maxWidth:"calc(100% - 8px)"}}
    label={primary}
    onDelete={handleToggle(value)}
/></Tooltip>

export const FacetPanel = ({facet}) => {
    configure({cache:false});
    const reduceCheckedByFacet = (acc,val) => {
        if(Base64.decode(val).includes(facet)) {
            acc.push(val);
        }
        return acc;
    } 
    const [page,setPage] = useState(1);
    const [filter,setFilter] = useState("");
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const params = useParams();
    const {query,facets} = params;
    const checked = facets ? facets.split(",").reduce(reduceCheckedByFacet,[]) : [];
    const limit = 10;
    const offset = (page - 1) * limit;
    // const solrQueryUrl = getSolrQueryUrlForFacet({facet, query, facets, limit, offset});
    const solrQueryUrl = getSolrQueryUrlForFacet({facet, query, facets, limit, offset:0});
    const [{ data, loading, error }] = useAxios(solrQueryUrl);
    const handleToggle = (value) => () => {
        let allFacets = facets ? facets.split(",") : [];
        if (allFacets.indexOf(value) === -1) {
            allFacets.push(value);
        } else {
            allFacets.splice(allFacets.indexOf(value), 1);
        }
        history.push(getSearchUrl({...params,facets:allFacets,page:1}));
    };
    let checkedItems = [];
    checked.reduce((acc, value) => {
        const decodedValue = unescapeForSolr(Base64.decode(value));
        if(decodedValue.includes(facet)) {
            const labelId = `checkbox-list-label-${value.replace(/[^a-zA-Z0-9]/,'-')}`;
            let primary = decodedValue.replace(/^[^:]+:"(.+)"$/,'$1');
            if(facet === "autoricnr_s-i-s-m") primary = primary.replace(/\$:(.+):\$$/,"");
            if(facet === "lingua_s-i-s-m") primary = getLanguageLabelByCode(primary);
            acc.push(<SelectedFacets key={value} value={value} checked={true} labelId={labelId} primary={primary} handleToggle={handleToggle}/>);
        }
        return acc;
    },checkedItems);
    // const buckets = data?.facets[facet]?.buckets || [];
    const buckets = (data?.facet_counts?.facet_fields[facet] || []).reduce((acc,item,i) => {
        if(i % 2 === 0) {
            acc.push({label:item});
        } else {
            acc[acc.length-1] = {
                val: acc[acc.length-1].label,
                count: item
            };  
        }
        return acc;
    },[]).reduce((acc,item) => {
        // pulizia valori non desiderati
        if(!/^([-._\s]+|\[object Object\]| \$::\$)$|^$/.test(item.val) && parsedContains(item.val, filter)) {
            acc.push(item);
        }
        return acc;
    },[]);
    const distinctCount = buckets.length;//data?.facets?.unique || 0;
    const numOfPages = Math.ceil(distinctCount/limit);
    let searchInput = React.createRef();
    useEffect(() => {
        setPage(1);
        setFilter("");
        searchInput.value='';
    }, [query, facets, searchInput.value]);
    useEffect(() => {
        setPage(1);
    }, [filter]);
    return (
        <Box mb={2}>
            <Card>
                <CardHeader
                    avatar={
                    <Avatar aria-label={facet} className={classes.hAvatar} style={(facet === 'progettieuropei_fundingprogram_s-i-s-m' || facet === 'progettieuropei_acronym_s-i-s-m') ? {color:"#FC0",backgroundColor:"#039"}:{}}>
                        {facetIcon(facet)}
                    </Avatar>
                    }
                    title={t(getFacetLabel(facet))}
                    subheader={(!error && (loading || !data)) ? <Skeleton width={"10em"}/> : `${t("Selected")} ${checkedItems.length}/${distinctCount}`}
                />
                <CardContent className={classes.p0}>
                    {!!checkedItems.length && <React.Fragment>
                        <Divider />
                        <Box p={1.5}>{checkedItems}</Box>
                        <Divider />
                    </React.Fragment>}
                    {!!error && <Box p={2}>
                        <Typography>{t("Loading error")}</Typography>
                        <PrintError {...{error}} />
                    </Box>}
                    {(!error && (loading || !data)) && Array.apply(null, Array(limit)).map((item,key) => <LoaderListItem key={key} />)}
                    {(!error && !loading && !!data) && ((distinctCount > 0) ? <List className={classes.list}>
                        {buckets.slice(offset,page*limit).map(({val,count},key) => {
                            const value = Base64.encodeURI(`${facet}:"${escapeForSolr(val)}"`);
                            const labelId = `checkbox-list-label-${value.replace(/[^a-zA-Z0-9]/,'-')}`;
                            let primary = val;
                            if(facet === "autoricnr_s-i-s-m") primary = primary.replace(/\$:(.+):\$$/,"");
                            if(facet === "lingua_s-i-s-m") primary = getLanguageLabelByCode(primary);
                            primary = `${primary} (${count})`;
                            const isChecked = checked.indexOf(value) !== -1;
                            return <LI key={key} value={value} checked={isChecked} labelId={labelId} primary={primary} handleToggle={handleToggle}/>
                        })}
                    </List> : <Box p={2}>{t("No values ​​available")}</Box>)}
                    {/* <PrintJson>{data?.facets}</PrintJson> */}
                </CardContent>
                <CardActions classes={{root:classes.actions}}>
                    <form className={classes.form} onSubmit={(ev)=>{
                        ev.preventDefault();
                        setFilter(searchInput.value);
                    }}>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder={`${t("Filter")} ...`}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                defaultValue={filter}
                                name='query'
                                autoComplete='off'
                                inputRef={el => searchInput = el}
                            />
                        </div>
                    </form>
                    {/* <span style={{flex:1}} /> */}
                    <Pagination classes={{ul:classes.pagination}} siblingCount={1} boundaryCount={1} count={numOfPages} page={page} onChange={(event,value) => {setPage(value)}} />
                </CardActions>
            </Card>
        </Box>
    );
}

const FacetsPanels = () => {
    const facetsFields = process.env.REACT_APP_FACETS_FIELDS.split(',');
    return facetsFields.map((facet,key) => <FacetPanel {...{key,facet}}/>);
}

export default FacetsPanels; 